import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'xspot-app-simply-layout',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './simply-layout.component.html',
  styleUrls: ['./simply-layout.component.scss'],
})
export class SimplyLayoutComponent {
  @Input({ required: true }) public backgroundUrl!: string;
}
