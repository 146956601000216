import { LanguageControlGroup } from '@xspot-app/common';

export interface UpsellDto {
  id: string;
  price: number;
  coverMobileUri: string;
  coverDesktopUri: string;
  name: LanguageControlGroup;
  description: LanguageControlGroup;
  highlight: LanguageControlGroup;
  teaser: LanguageControlGroup;
}
