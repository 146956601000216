export interface MimeType {
  name: string;
  fileFormat: string;
}

export const IMAGE_MIME_TYPES = {
  jpg: { name: 'image/jpg', fileFormat: 'jpg' } as MimeType,
  jpeg: { name: 'image/jpeg', fileFormat: 'jpeg' } as MimeType,
  png: { name: 'image/png', fileFormat: 'png' } as MimeType,
};
export const VIDEO_MIME_TYPES = {
  mp4: { name: 'video/mp4', fileFormat: '.mp4' } as MimeType,
};
