import { OfferVariantDto } from '../dtos';
import { LocationControlModel } from './location-control.model';
import { SelectedSlot } from './selected-slot.model';
import { Nullable } from '@xspot-app/common';

export interface OfferModel {
  id: string;
  activeIndex: number;
  location?: Nullable<LocationControlModel>;
  variant?: Nullable<OfferVariantDto>;
  variantProducts?: Record<string, number>;
  upsells?: Record<string, number>;
  selectedDate?: Nullable<Date>;
  selectedSlots?: SelectedSlot[];
}
