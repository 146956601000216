import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CalendarDaysDto,
  CalendarDaysSlotDto,
  CustomerOfferDto,
  CustomerOffersListDto,
  GetCalendarDaysSlotDto,
  GetOfferCalendarDto,
  GetSelectedProductsMinimalPriceDto,
  SelectedProductsMinimalPriceDto,
  UpsellDto,
} from '../dtos';
import { environment } from '@xspot-app/common';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) {}

  public getOfferList(): Observable<CustomerOffersListDto[]> {
    return this.http.get<CustomerOffersListDto[]>(
      `${environment.apiUrl}/api/offers`
    );
  }

  public getOfferById(offerId: string): Observable<CustomerOfferDto> {
    let headers = new HttpHeaders();
    const { backOfficeToken } = this.route.snapshot.queryParams;

    if (backOfficeToken) {
      headers = headers.set('X-BackOfficeOfferToken', backOfficeToken);
    }

    return this.http.get<CustomerOfferDto>(
      `${environment.apiUrl}/api/offers/${offerId}`,
      { headers }
    );
  }

  public getMinimalPrice(
    offerId: string,
    payload: GetSelectedProductsMinimalPriceDto
  ): Observable<SelectedProductsMinimalPriceDto> {
    let headers = new HttpHeaders();
    const { backOfficeToken } = this.route.snapshot.queryParams;

    if (backOfficeToken) {
      headers = headers.set('X-BackOfficeOfferToken', backOfficeToken);
    }

    return this.http.post<SelectedProductsMinimalPriceDto>(
      `${environment.apiUrl}/api/offers/${offerId}/MinimalPrice`,
      payload,
      { headers }
    );
  }

  public getOfferCalendar(
    payload: GetOfferCalendarDto
  ): Observable<CalendarDaysDto> {
    let headers = new HttpHeaders();
    const { backOfficeToken } = this.route.snapshot.queryParams;

    if (backOfficeToken) {
      headers = headers.set('X-BackOfficeOfferToken', backOfficeToken);
    }

    return this.http.post<CalendarDaysDto>(
      `${environment.apiUrl}/api/calendars/days`,
      payload,
      { headers }
    );
  }

  public getOfferCalendarSlots(
    payload: GetCalendarDaysSlotDto
  ): Observable<CalendarDaysSlotDto[]> {
    let headers = new HttpHeaders();
    const { backOfficeToken } = this.route.snapshot.queryParams;

    if (backOfficeToken) {
      headers = headers.set('X-BackOfficeOfferToken', backOfficeToken);
    }

    return this.http.post<CalendarDaysSlotDto[]>(
      `${environment.apiUrl}/api/calendars/days/slots`,
      payload,
      { headers }
    );
  }

  public getOfferUpsells(offerId: string): Observable<UpsellDto[]> {
    return this.http.get<UpsellDto[]>(
      `${environment.apiUrl}/api/offers/${offerId}/upsells`
    );
  }
}
