import { FormControl } from '@angular/forms';
import { LocationControlModel } from './location-control.model';
import { SelectedSlot } from './selected-slot.model';
import { Nullable } from '@xspot-app/common';
import { CustomerOfferVariantModel } from './customer-offer.model';

export interface OfferFormModel {
  location: FormControl<Nullable<LocationControlModel>>;
  variant: FormControl<Nullable<CustomerOfferVariantModel>>;
  variantProducts: FormControl<Record<string, number>>;
  upsells: FormControl<Record<string, number>>;
  selectedDate: FormControl<Nullable<Date>>;
  selectedSlots: FormControl<SelectedSlot[]>;
}
