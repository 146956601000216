import { ExternalLogInModel } from '../domain';
import {
  ConfirmEmailDto,
  LogInDto,
  ResendEmailConfirmationDto,
  ResetPasswordConfirmDto,
  ResetPasswordRequestDto,
} from '../dtos';

export namespace Auth {
  export class LogIn {
    public static readonly type = '[Auth] Log In';
    constructor(public payload: LogInDto) {}
  }
  export class ExternalLogIn {
    public static readonly type = '[Auth] External Log In';
    constructor(public payload: ExternalLogInModel) {}
  }

  export class ExternalLogInMoreInfo {
    public static readonly type = '[Auth] External Log In More Info';
  }

  export class Logout {
    public static readonly type = '[Auth] Logout';
  }

  export class ConfirmEmail {
    public static readonly type = '[Auth] Confirm Email';
    constructor(public payload: ConfirmEmailDto) {}
  }

  export class ResendEmailConfirmation {
    public static readonly type = '[Auth] Resend Email Confirmation';
    constructor(public payload: ResendEmailConfirmationDto) {}
  }

  export class ResetPasswordRequest {
    public static readonly type = '[Auth] Reset Password Request';
    constructor(public payload: ResetPasswordRequestDto) {}
  }

  export class ResetPasswordConfirm {
    public static readonly type = '[Auth] Reset Password Confirm';
    constructor(public payload: ResetPasswordConfirmDto) {}
  }
}
