import { DateTime } from 'luxon';
import { DateOffset, DateOffsetEnum } from '../enums';
import { Nullable } from './types';

export function toIsoString(
  date: Date,
  time: Nullable<string> = null,
  offset?: DateOffset
): string {
  let dateTime = DateTime.fromJSDate(date);
  if (!dateTime.isValid) throw new Error('Invalid date');

  if (time) {
    const [hours, minutes] = time.split(':').map(Number);
    dateTime = dateTime.set({ hour: hours, minute: minutes });
  } else {
    switch (offset) {
      case DateOffsetEnum.Start:
        dateTime = dateTime.startOf('day');
        break;
      case DateOffsetEnum.End:
        dateTime = dateTime.endOf('day');
        break;
      default:
        throw new Error('Invalid offset');
    }
  }

  return dateTime.toUTC().toString();
}

export function toDateOnlyIsoString(date: Date): string {
  const dateOnly = DateTime.fromJSDate(date).startOf('day');
  if (!dateOnly.isValid) throw new Error('Invalid date');
  return dateOnly.toISO().split('T')[0];
}
