import { SortOrder } from '../enums/sort-order.enum';
import { Nullable } from '../helpers';

export interface FilterListDto<T, F extends FiltersDto> {
  pageSize: number;
  pageNumber: number;
  sortOrder: SortOrder;
  orderBy: T;
  filter: F;
}

export interface FiltersDto {
  searchQuery: Nullable<string>;
}
