import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'fullTextDate',
  standalone: true,
})
export class FullTextDatePipe extends DatePipe implements PipeTransform {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public override transform(value: any, language: string): any {
    const weekday: string | null = super.transform(
      value,
      'EEEE',
      undefined,
      language
    );
    const day: string | null = super.transform(value, 'd', undefined, language);
    const month: string | null = super.transform(
      value,
      'LLLL',
      undefined,
      language
    );
    const year: string | null = super.transform(
      value,
      'y',
      undefined,
      language
    );

    return `${weekday} ${day} ${month} ${year}`;
  }
}
