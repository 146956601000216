import { Component, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PaginatorModule } from 'primeng/paginator';
import { PasswordModule } from 'primeng/password';
import { provideTranslocoScope, TranslocoDirective } from '@ngneat/transloco';
import {
  PasswordValidator,
  scopeLoaderFactory,
  StringUtil,
  ValidationTextColorDirective,
} from '@xspot-app/common';
import { DividerModule } from 'primeng/divider';

@UntilDestroy()
@Component({
  selector: 'xspot-app-password',
  standalone: true,
  imports: [
    CommonModule,
    PaginatorModule,
    PasswordModule,
    ReactiveFormsModule,
    TranslocoDirective,
    DividerModule,
    ValidationTextColorDirective,
  ],
  templateUrl: './password.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true,
    },
    provideTranslocoScope({
      scope: 'password',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
  ],
})
export class PasswordComponent implements ControlValueAccessor, Validator {
  public passwordControl: FormControl<string>;

  public get errors(): ValidationErrors | null {
    return this.passwordControl.errors;
  }

  public onTouched: () => void = () => {
    this.passwordControl.markAsTouched();
    this.passwordControl.updateValueAndValidity();
  };

  constructor(private fb: NonNullableFormBuilder) {
    this.passwordControl = this.fb.control(StringUtil.empty, [
      Validators.required,
      PasswordValidator,
    ]);
  }

  public validate(
    control: AbstractControl<string, string>
  ): ValidationErrors | null {
    if (control.value === StringUtil.empty) {
      return null;
    }
    const errors = PasswordValidator(control);
    if (errors) {
      return { ...errors };
    }
    return null;
  }
  public registerOnChange(fn: (data: string) => void): void {
    this.passwordControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => fn(this.passwordControl.value));
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(data: string): void {
    this.passwordControl.setValue(data);
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.passwordControl.disable();
    } else {
      this.passwordControl.enable();
    }
  }
}
