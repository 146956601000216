import { AbstractControl } from '@angular/forms';
import { formDateFormat } from '../consts';
import { DateTime } from 'luxon';
import { Nullable } from '../helpers';

export function DateGreaterThanOrEqualToValidator(
  compareTo: Nullable<AbstractControl>
) {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const inputValue: string = control.value;
    const compareToValue: string = compareTo?.value;

    if (!inputValue || !compareToValue) {
      return null;
    }

    const parsedControlDate = DateTime.fromFormat(inputValue, formDateFormat);
    const parsedCompareToDate = DateTime.fromFormat(
      compareToValue,
      formDateFormat
    );

    if (
      parsedControlDate.isValid &&
      parsedCompareToDate.isValid &&
      parsedControlDate < parsedCompareToDate
    ) {
      return {
        dateGreaterThanOrEqualTo: true,
      };
    }

    return null;
  };
}
