import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  CreateExternalUserDto,
  CreateUserDto,
  UpdateUserAgreementsDto,
  UpdateUserProfileDto,
  UserProfileDto,
} from '../dtos';
import { environment } from '@xspot-app/common';
import { GusCompanyDataDto } from '../dtos/gus-company-data.dto';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public create(data: CreateUserDto): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/api/users`, {
      ...data,
    });
  }

  public fetchProfile(): Observable<UserProfileDto> {
    return this.http.get<UserProfileDto>(
      `${environment.apiUrl}/api/users/profile`
    );
  }
  public updateProfile(data: UpdateUserProfileDto): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/api/users/profile`, {
      ...data,
    });
  }

  public createExternalUser(data: CreateExternalUserDto): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/api/users/external`, {
      ...data,
    });
  }

  public updateAgreements(data: UpdateUserAgreementsDto): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/api/users/agreements`, {
      ...data,
    });
  }

  public getGusCompanyData(taxNumber: string): Observable<GusCompanyDataDto> {
    return this.http.get<GusCompanyDataDto>(
      `${environment.apiUrl}/api/users/getGusCompanyData/${taxNumber}`
    );
  }
}
