import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  Component,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl } from '@angular/forms';
import {
  provideTranslocoScope,
  TranslocoDirective,
  TranslocoPipe,
} from '@ngneat/transloco';
import { scopeLoaderFactory } from '@xspot-app/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'xspot-app-form-control-errors',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslocoDirective, TranslocoPipe],
  templateUrl: './form-control-errors.component.html',
  providers: [
    provideTranslocoScope({
      scope: 'errors',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
  ],
})
export class FormControlErrorsComponent implements OnInit {
  @Input({ required: true }) public control!: AbstractControl | null;
  @Input() public prefix: string = '';

  public get errors(): { [key: string]: string } {
    return this.control?.errors || {};
  }

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (this.control) {
      this.control.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
        this.cdr.markForCheck();
      });
    }
  }
}
