import { BasketModel } from '../../domain';
import { BasketItemDto } from '../../dtos';

export namespace Baskets {
  export class GetItems {
    public static readonly type = '[Baskets] Get items';
  }

  export class AddItem {
    public static readonly type = '[Baskets] Add item';
    constructor(public payload: BasketItemDto) {}
  }

  export class UpdateItem {
    public static readonly type = '[Baskets] Update item';
    constructor(public payload: BasketItemDto) {}
  }

  export class UpdateCurrent {
    public static readonly type = '[Baskets] Update current';
    constructor(public payload: BasketModel) {}
  }

  export class DeleteItem {
    public static readonly type = '[Baskets] Delete item';
    constructor(public id: string) {}
  }
}
