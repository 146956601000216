import { produce } from 'immer';
import { LanguageControlGroup } from '@xspot-app/common';
import { CustomerLocationsListDto } from '@xspot-app/customer/locations-api';

export function mapLocations<
  T extends {
    locationId: string;
    locationName?: string | LanguageControlGroup;
  },
>(products: T[], locations: CustomerLocationsListDto[]): T[] {
  return produce(products, draft => {
    draft.forEach(product => {
      const location = locations.find(
        location => location.id === product.locationId
      );
      product.locationName = location
        ? location.name
        : ({} as LanguageControlGroup);
    });
  });
}
