import { DateTime } from 'luxon';

export function tomorrow(): DateTime {
  return today().plus({ day: 1 });
}

export function today(): DateTime {
  return DateTime.local().startOf('day');
}

export function twoYears(): DateTime {
  return today().plus({ months: 24 });
}

export function addMinutesToTime(time: string, minutesToAdd: number): string {
  const [hours, minutes] = time.split(':').map(Number);

  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  date.setMinutes(date.getMinutes() + minutesToAdd);

  const updatedHours = String(date.getHours()).padStart(2, '0');
  const updatedMinutes = String(date.getMinutes()).padStart(2, '0');

  return `${updatedHours}:${updatedMinutes}`;
}
