import { AbstractControl } from '@angular/forms';

export function EmailValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const email: string = control.value;

  const emailRegex =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

  if (email) {
    const emailValid = emailRegex.test(email);
    if (!emailValid) {
      return { invalidEmail: true };
    }
  }
  return null;
}
