import { Nullable, SlotRestrictedOfferKindEnum } from '@xspot-app/common';
import { OfferStatusEnum } from '../enums';
import { PriceDto } from '../dtos';

export interface OfferModel {
  id: string;
  name: string;
  kind: SlotRestrictedOfferKindEnum;
  isHidden: boolean;
  variantsCount: number;
  status: OfferStatusEnum;
  price: Nullable<PriceDto>;
}
