import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FormControlService {
  private _validate$ = new Subject<void>();

  public readonly validate$ = this._validate$.asObservable();

  public validate(): void {
    this._validate$.next();
  }
}
