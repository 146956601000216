import { Nullable, OfferMediaKindEnum } from '@xspot-app/common';
import { FileModel } from './file.model';

export interface MediaTableElementModel {
  file: Nullable<FileModel>;
  url: Nullable<string>;
  ordinalNumber: number;
  kind: OfferMediaKindEnum;
  isNewFile: boolean;
}
