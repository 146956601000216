import { ErrorMessage } from '../dtos';

export function getErrorMessage(
  errorCode: string,
  dictionary: { [key: string]: string },
  fn?: () => 'warn' | 'error'
): ErrorMessage {
  const summary = dictionary[errorCode];
  if (!summary)
    throw new Error(`No error message found for error code ${errorCode}`);
  return {
    severity: fn ? fn() : 'error',
    summary,
  };
}
