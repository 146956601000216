import { Injectable } from '@angular/core';
import {
  StateToken,
  State,
  Action,
  StateContext,
  Selector,
  Store,
} from '@ngxs/store';
import { PaymentsStateModel } from './payments.state-model';
import { Payments } from './payments.actions';
import { Observable, tap } from 'rxjs';
import { PaymentsService } from '../../services/payments.service';
import { LocationsState } from '@xspot-app/customer/locations-api';
import { CreatePaymentResultDto } from '../../dtos';
import { mapLocations } from '../../mappers';

const PAYMENTS_STATE_TOKEN = new StateToken<PaymentsStateModel>('payments');

@State<PaymentsStateModel>({
  name: PAYMENTS_STATE_TOKEN,
  defaults: {
    response: null,
  },
})
@Injectable()
export class PaymentsState {
  constructor(
    private store: Store,
    private paymentsService: PaymentsService
  ) {}

  @Selector()
  public static response(
    state: PaymentsStateModel
  ): PaymentsStateModel['response'] {
    return state.response;
  }

  @Action(Payments.CreateBasketPayment)
  public createBasketPayment(
    ctx: StateContext<PaymentsStateModel>,
    { payload }: Payments.CreateBasketPayment
  ): Observable<CreatePaymentResultDto> {
    return this.paymentsService.createBasketPayment(payload).pipe(
      tap(response => {
        const data = { ...response };
        const locations = this.store.selectSnapshot(LocationsState.all);

        if (
          data.invalidBasket?.priceChanged &&
          Array.isArray(data.invalidBasket.priceChanged)
        ) {
          data.invalidBasket.priceChanged = mapLocations(
            data.invalidBasket.priceChanged,
            locations
          );
        }

        if (
          data.invalidBasket?.unavailable &&
          Array.isArray(data.invalidBasket.unavailable)
        ) {
          data.invalidBasket.unavailable = mapLocations(
            data.invalidBasket.unavailable,
            locations
          );
        }

        ctx.patchState({ response: data });
      })
    );
  }
}
