export interface OffersTreeOfferNodeDto {
  id: string;
  name: string;
  variants: OffersTreeVariantNodeDto[];
}

export interface OffersTreeVariantNodeDto {
  id: string;
  name: string;
  products: OffersTreeProductNodeDto[];
}

export interface OffersTreeProductNodeDto {
  id: string;
  name: string;
  displayName: string;
}
