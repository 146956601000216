import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  filter,
  Observable,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  AUTH_INTERCEPTOR_CONFIG,
  AuthService,
  ErrorUtil,
} from '@xspot-app/common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    @Inject(AUTH_INTERCEPTOR_CONFIG)
    private config: {
      apiEndpointForRefreshToken: string;
    },
    private router: Router
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const accessToken = this.authService.getAccessToken();
    if (accessToken) {
      request = this.addTokenToRequest(request, accessToken);
    }
    return next.handle(request).pipe(
      catchError(error => {
        if (ErrorUtil.isUnauthorizedError(error)) {
          return this.handle401Error(request, next);
        }
        return throwError(() => error);
      })
    );
  }

  private addTokenToRequest(
    request: HttpRequest<unknown>,
    token: string
  ): HttpRequest<unknown> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  private handle401Error(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.authService.getRefreshToken();
      if (refreshToken) {
        return this.http
          .post<{
            accessToken: string;
            refreshToken: string;
          }>(this.config.apiEndpointForRefreshToken, {
            refreshToken: refreshToken,
          })
          .pipe(
            switchMap(
              (tokenResponse: {
                accessToken: string;
                refreshToken: string;
              }) => {
                this.isRefreshing = false;
                this.authService.setToken(
                  tokenResponse.accessToken,
                  tokenResponse.refreshToken
                );
                this.refreshTokenSubject.next(tokenResponse.accessToken);

                return next.handle(
                  this.addTokenToRequest(request, tokenResponse.accessToken)
                );
              }
            ),
            catchError(err => {
              if (ErrorUtil.isUnauthorizedOrInvalidRefreshTokenError(err)) {
                this.authService.clearTokens();
                this.router.navigate(['auth']);
              }
              this.isRefreshing = false;
              return throwError(() => err);
            })
          );
      } else {
        return throwError(() => new Error('NO_REFRESH_TOKEN'));
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(accessToken => {
          return next.handle(
            this.addTokenToRequest(request, accessToken as string)
          );
        })
      );
    }
  }
}
