import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { environment } from '@xspot-app/common';
import { GetVoucherDto, VoucherDto } from '../dtos';
import { delay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  // constructor(private http: HttpClient) {}

  // @TODO mock, CHAT GPT teksty xD
  private vouchers: VoucherDto[] = [
    {
      id: '1',
      code: 'ABC123',
      title: 'Discount 10%',
      description: '10% off on all items',
      validDate: '2024-12-31',
    },
    {
      id: '2',
      code: 'DEF456',
      title: 'Free Shipping',
      description: 'Free shipping for orders above $50',
      validDate: '2024-11-30',
    },
    {
      id: '3',
      code: 'GHI789',
      title: 'Buy One Get One',
      description: 'Buy one get one free on select items',
      validDate: '2024-10-15',
    },
    {
      id: '4',
      code: 'JKL012',
      title: 'Black Friday Special',
      description: '20% off during Black Friday',
      validDate: '2024-11-28',
    },
    {
      id: '5',
      code: 'MNO345',
      title: 'Cyber Monday Deal',
      description: '15% off on electronics',
      validDate: '2024-12-01',
    },
    {
      id: '6',
      code: 'PQR678',
      title: 'Summer Sale',
      description: '25% off during summer sale',
      validDate: '2024-07-31',
    },
    {
      id: '7',
      code: 'STU901',
      title: 'Christmas Offer',
      description: '30% off on all products',
      validDate: '2024-12-25',
    },
    {
      id: '8',
      code: 'VWX234',
      title: 'New Year Sale',
      description: '15% off in January',
      validDate: '2025-01-31',
    },
    {
      id: '9',
      code: 'YZA567',
      title: 'Easter Deal',
      description: '10% off on Easter weekend',
      validDate: '2025-04-10',
    },
    {
      id: '10',
      code: 'BCD890',
      title: 'Anniversary Sale',
      description: 'Special discounts for our anniversary',
      validDate: '2025-05-15',
    },
  ];

  // @TODO undefined poniewaz to mock i moze nie znalezc, proste!
  public getVoucher(payload: GetVoucherDto): Observable<VoucherDto> {
    const voucher =
      this.vouchers.find(v => v.code === payload.code) || this.vouchers[0];
    return of(voucher).pipe(delay(150));
  }
}
