import { Component, ContentChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolBarDirective } from '../../directives';

@Component({
  selector: 'xspot-app-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './page.component.html',
  styleUrl: './page.component.scss',
})
export class PageComponent {
  @ContentChildren(ToolBarDirective) public toolBars!: ToolBarDirective[];
}
