<div
  class="flex align-content-center align-self-stretch mb-3 lg:mt-6"
  [ngClass]="
    hasBackButton
      ? 'justify-content-between lg:justify-content-start'
      : 'justify-content-center lg:justify-content-start'
  ">
  <p-button
    *ngIf="hasBackButton"
    severity="secondary"
    [text]="true"
    class="back-button"
    icon="pi pi-arrow-left"
    (click)="back()"></p-button>
  <img class="cursor-pointer" [src]="logoUrl" alt="logo" routerLink="/" />
  <p-button
    *ngIf="hasBackButton"
    class="button--hidden"
    [text]="true"></p-button>
</div>
