import { Injectable, OnDestroy } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export const BREAKPOINTS = {
  lg: '(min-width: 992px)',
  md: '(min-width: 768px)',
  sm: '(min-width: 576px)',
  xs: '(min-width: 0px)',
};

@Injectable({
  providedIn: 'root',
})
export class BreakpointService implements OnDestroy {
  public isLargeScreen$: Observable<boolean>;
  private destroy$ = new Subject<void>();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isLargeScreen$ = this.breakpointObserver
      .observe([BREAKPOINTS.lg])
      .pipe(
        map((state: BreakpointState) => state.matches),
        takeUntil(this.destroy$)
      );
  }

  public isLargeScreen(): Observable<boolean> {
    return this.breakpointObserver
      .observe([BREAKPOINTS.lg])
      .pipe(map((state: BreakpointState) => state.matches));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
