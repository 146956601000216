import { Directive, HostListener } from '@angular/core';
import { EditorInitEvent } from 'primeng/editor';

@Directive({
  standalone: true,
  selector: '[blockEditorImages]',
})
export class BlockEditorImagesDirective {
  @HostListener('onInit', ['$event'])
  public onInit(event: EditorInitEvent): void {
    this.setImageBlockerMatcher(event);
  }

  private setImageBlockerMatcher(event: EditorInitEvent): void {
    event.editor.clipboard.addMatcher('IMG', () => {
      return { ops: [] };
    });
  }
}
