import { Component, Input } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'xspot-app-auth-navigation',
  standalone: true,
  imports: [CommonModule, ButtonModule, RouterLink],
  templateUrl: './auth-navigation.component.html',
  styleUrls: ['./auth-navigation.component.scss'],
})
export class AuthNavigationComponent {
  @Input({ required: true }) public logoUrl!: string;
  @Input() public hasBackButton = true;

  constructor(private location: Location) {}

  public back(): void {
    this.location.back();
  }
}
