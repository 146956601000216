import { AbstractControl } from '@angular/forms';
import { DateTime } from 'luxon';
import { formDateFormat } from '../consts';

export function futureDateValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const date: string | Date = control.value;

  if (!date) {
    return null;
  }

  let parsedDate: DateTime;

  if (date instanceof Date) {
    parsedDate = DateTime.fromJSDate(date);
  } else {
    parsedDate = DateTime.fromFormat(date, formDateFormat);
  }

  const now = DateTime.local();
  if (parsedDate.startOf('day') <= now.startOf('day')) {
    return { dateInPast: true };
  }
  return null;
}
