import { Language } from '@xspot-app/common';

export const AvailableLanguages = {
  [Language.Polish]: 'pl',
  [Language.English]: 'en',
};

export const AvailableCustomerLanguages = {
  [Language.Polish]: 'pl',
  [Language.English]: 'en',
  [Language.German]: 'de',
  [Language.Czech]: 'cs',
  [Language.Spanish]: 'es',
  [Language.French]: 'fr',
  [Language.Ukrainian]: 'uk',
};

export const defaultLanguage = AvailableLanguages[Language.Polish];
