import {
  AgeGroupKindEnum,
  CreditKindEnum,
  LanguageControlGroup,
  Nullable,
  OfferCategoryEnum,
  OfferMediaKindEnum,
  OfferProductPriceKindEnum,
  OfferProductFormEnum,
  RelatedLocationDto,
  RelatedUpsellDto,
  SlotKindEnum,
  OfferKindEnum,
} from '@xspot-app/common';
import { OfferStatusEnum } from '../enums';
import { PriceDto } from './price.dto';
import { VolumePriceDiscountKindEnum } from '@xspot-app/back-office/volume-price-api';

export interface OfferDetailsDto {
  id: string;
  coverMobileUri: string;
  coverDesktopUri: string;
  isHidden: boolean;
  name: LanguageControlGroup;
  teaser: LanguageControlGroup;
  description: LanguageControlGroup;
  kind: OfferKindEnum;
  limitedOffersNumber: Nullable<number>;
  maxOffersPerClient: Nullable<number>;
  legalContent: LanguageControlGroup;
  status: OfferStatusEnum;
  locations: RelatedLocationDto[];
  media: OfferMediaDto[];
  legalFiles: OfferLegalFileDto[];
  variants: OfferVariantDto[];
  upsells: RelatedUpsellDto[];
  categories: OfferCategoryEnum[];
}

export interface OfferMediaDto {
  id: string;
  kind: OfferMediaKindEnum;
  uri: string;
  fileName: string;
  ordinal: number;
}

export interface OfferLegalFileDto {
  id: string;
  fileName: string;
  uri: string;
  ordinal: number;
}

export interface OfferVariantDto {
  id: string;
  publicationStartDate: string;
  publicationEndDate: string;
  name: LanguageControlGroup;
  information: LanguageControlGroup;
  ordinal: number;
  priceDto: PriceDto;
  entries: OfferVariantEntryDto[];
  products: OfferVariantProductDto[];
}

export interface OfferVariantEntryDto {
  id: string;
  header: LanguageControlGroup;
  description: LanguageControlGroup;
  ordinal: number;
}

export interface OfferVariantProductDto {
  id: string;
  productId: string;
  reservationDetails: Nullable<OfferVariantReservationProductDto>;
  creditDetails: Nullable<OfferVariantCreditProductDto>;
  supplementDetails: Nullable<OfferVariantSupplementProductDto>;
  productForm: OfferProductFormEnum;
  minAmount: Nullable<number>;
  maxAmount: Nullable<number>;
  startAmount: Nullable<number>;
  amount: Nullable<number>;
  ordinal: number;
  priceKind: OfferProductPriceKindEnum;
  singlePrice: Nullable<number>;
  volumePrice: Nullable<OfferVariantProductVolumePriceDto>;
  segmentPrices: OfferVariantProductSegmentPriceDto[];
}

export interface OfferVariantReservationProductDto {
  productName: string;
  isMinimalAmount: boolean;
  amount: number;
  ageGroupKind: AgeGroupKindEnum;
}

export interface OfferVariantCreditProductDto {
  productName: string;
  creditKind: CreditKindEnum;
  expireAt: Nullable<string>;
  expireInMonths: Nullable<number>;
  amount: number;
}

export interface OfferVariantSupplementProductDto {
  productName: string;
}

export interface OfferVariantProductSegmentPriceDto {
  slotKind: SlotKindEnum;
  price: number;
}

export interface OfferVariantProductVolumePriceDto {
  id: string;
  name: string;
  discountKind: VolumePriceDiscountKindEnum;
  items: OfferVariantProductVolumePriceItemDto[];
}

export interface OfferVariantProductVolumePriceItemDto {
  from: number;
  discountValue: number;
}
