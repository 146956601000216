import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@xspot-app/common';
import {
  ConfirmEmailDto,
  ExternalLogInDto,
  LogInDto,
  LoginResultDto,
  ResendEmailConfirmationDto,
  ResetPasswordConfirmDto,
  ResetPasswordRequestDto,
} from '../dtos';
import { AuthenticationDto } from '@xspot-app/shared/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(private http: HttpClient) {}

  public login(data: LogInDto): Observable<LoginResultDto> {
    return this.http.post<LoginResultDto>(
      `${environment.apiUrl}/api/authorization/logIn`,
      {
        ...data,
      }
    );
  }

  public externalLogin(data: ExternalLogInDto): Observable<AuthenticationDto> {
    return this.http.post<AuthenticationDto>(
      `${environment.apiUrl}/api/authorization/externalLogIn`,
      {
        ...data,
      }
    );
  }

  public refreshToken(data: {
    refreshToken: string;
  }): Observable<LoginResultDto> {
    return this.http.post<LoginResultDto>(
      `${environment.apiUrl}/api/authorization/RefreshToken`,
      {
        ...data,
      }
    );
  }

  public confirmEmail(data: ConfirmEmailDto): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/api/authorization/confirmEmail`,
      { ...data }
    );
  }

  public resendEmailConfirmation(
    data: ResendEmailConfirmationDto
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/api/authorization/resendEmailConfirmation`,
      { ...data }
    );
  }

  public resetPassword(data: ResetPasswordRequestDto): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/api/authorization/resetPassword/request`,
      { ...data }
    );
  }

  public resetPasswordConfirm(data: ResetPasswordConfirmDto): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/api/authorization/resetPassword/confirm`,
      { ...data }
    );
  }
}
