import { LanguageControlGroup } from '@xspot-app/common';
import { LocationsDto } from '@xspot-app/shared/locations';
import { OfferMediaDto } from './offer-media.dto';
import { OfferLegalFileDto } from './offer-legal-file.dto';
import { OfferVariantDto } from './offer-variant.dto';

export interface CustomerOfferDto {
  id: string;
  name: LanguageControlGroup;
  teaser: LanguageControlGroup;
  description: LanguageControlGroup;
  legalContent: LanguageControlGroup;
  locations: LocationsDto;
  media: OfferMediaDto[];
  legalFiles: OfferLegalFileDto[];
  variants: OfferVariantDto[];
  hasUpsells: boolean;
}
