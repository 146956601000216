import { Language } from '@xspot-app/common';
import { UserAgreementModel } from './user-agreement.model';

export interface UserProfileModel {
  email: string;
  name: string;
  surname: string;
  dateOfBirth: string | null;
  language: Language;
  emailConfirmed: boolean | null;
  phoneNumberPrefix: string;
  phoneNumberWithoutPrefix: string;
  agreements: UserAgreementModel[];
}
