import { Nullable } from '@xspot-app/common';
import { CommonModule } from '@angular/common';
import { Component, Input, forwardRef } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'xspot-app-counter-field',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ButtonModule, InputNumberModule],
  styleUrls: ['./counter-field.component.scss'],
  templateUrl: './counter-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CounterFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CounterFieldComponent),
      multi: true,
    },
  ],
})
export class CounterFieldComponent implements ControlValueAccessor, Validator {
  @Input() public formControl!: FormControl<Nullable<number>>;

  public increment(): void {
    const currentValue = this.formControl.value || 0;
    this.formControl.setValue(currentValue + 1);
    this.onChange(this.formControl.value || 0);
  }

  public decrement(): void {
    const currentValue = this.formControl.value || 0;
    if (currentValue > 0) {
      this.formControl.setValue(currentValue - 1);
    }
    this.onChange(this.formControl.value || 0);
  }

  public onChange: (value: number) => void = () => {};
  public onTouched: (value: number) => void = () => {};

  public registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: (value: number) => void): void {
    this.onTouched = fn;
  }

  public writeValue(data: number): void {
    if (this.formControl && this.formControl.value != data) {
      this.formControl.setValue(data, { emitEvent: false });
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
  public validate(): ValidationErrors | null {
    return this.formControl.value && this.formControl.value >= 0
      ? null
      : {
          invalidForm: {
            valid: false,
            message: 'The count cannot be less than zero.',
          },
        };
  }
}
