import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { appendArray, environment } from '@xspot-app/common';
import { LibraryFilesDto } from '../dtos/library-files.dto';

@Injectable({
  providedIn: 'root',
})
export class FilesLibraryUploadService {
  constructor(private http: HttpClient) {}

  public upload(files: File[]): Observable<LibraryFilesDto> {
    const formData = new FormData();
    appendArray(formData, 'files', files);

    return this.http.post<LibraryFilesDto>(
      `${environment.apiUrl}/backoffice/libraryFiles`,
      formData
    );
  }
}
