import { MenuType } from '../enums';

export interface MenuTopBar {
  separator?: boolean;
  label?: string;
  icon?: string;
  routerLink?: string;
  type?: MenuType;
  action?: () => void;
}
