import { FormControl } from '@angular/forms';
import { Nullable } from '@xspot-app/common';

type ProductDetails = Nullable<{ productName: string }>;

export interface SelectedVariantProductDto {
  id: string;
  productId: string;
  productName?: string;
  reservationDetails?: ProductDetails;
  creditDetails?: ProductDetails;
  supplementDetails?: ProductDetails;
  quantity: FormControl<Nullable<number>>;
}
