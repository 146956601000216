import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  environment,
  pagedListQueryParams,
  StringUtil,
} from '@xspot-app/common';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  GetOfferListDto,
  OfferListDto,
  OrderedOfferDto,
  OffersTreeOfferNodeDto,
  UpdateOfferOrderDto,
  CalculateVariantPriceProductDto,
  PriceDto,
  OfferDetailsDto,
  CreateOfferDto,
} from '../dtos';
import { UpdateOfferDto } from '../dtos/update-offer-dto';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  constructor(private http: HttpClient) {}

  private searchQuerySource = new BehaviorSubject<string>(StringUtil.empty);
  public searchQuery$ = this.searchQuerySource.asObservable();

  public setSearchQuery(query: string): void {
    this.searchQuerySource.next(query);
  }

  public addOffers(payload: CreateOfferDto): Observable<CreateOfferDto> {
    return this.http.post<CreateOfferDto>(
      `${environment.apiUrl}/backoffice/offers`,
      {
        ...payload,
      }
    );
  }

  public getOfferList(filter: GetOfferListDto): Observable<OfferListDto> {
    const params = pagedListQueryParams(filter);
    return this.http.get<OfferListDto>(
      `${environment.apiUrl}/backoffice/offers/`,
      { params }
    );
  }

  public getOrderedOfferList(): Observable<OrderedOfferDto[]> {
    return this.http.get<OrderedOfferDto[]>(
      `${environment.apiUrl}/backoffice/offers/ordered`
    );
  }

  public updateOffersOrder(offers: UpdateOfferOrderDto[]): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/backoffice/offers/order`,
      offers
    );
  }

  public deleteOffer(id: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiUrl}/backoffice/offers/${id}`
    );
  }

  public activate(id: string): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}/backoffice/offers/${id}/activate`,
      {}
    );
  }

  public deactivate(id: string): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}/backoffice/offers/${id}/deactivate`,
      {}
    );
  }

  public updateOffer(payload: UpdateOfferDto): Observable<UpdateOfferDto> {
    return this.http.put<UpdateOfferDto>(
      `${environment.apiUrl}/backoffice/offers/${payload.id}`,
      {
        ...payload,
      }
    );
  }

  public getById(id: string): Observable<OfferDetailsDto> {
    return this.http.get<OfferDetailsDto>(
      `${environment.apiUrl}/backoffice/offers/${id}`
    );
  }

  public getOffersTree(): Observable<OffersTreeOfferNodeDto[]> {
    return this.http.get<OffersTreeOfferNodeDto[]>(
      `${environment.apiUrl}/backoffice/offers/tree`
    );
  }

  public calculateVariantPrice(
    payload: CalculateVariantPriceProductDto[]
  ): Observable<PriceDto> {
    return this.http.post<PriceDto>(
      `${environment.apiUrl}/backoffice/offers/calculatevariantprice`,
      { variantProducts: [...payload] }
    );
  }
}
