import { LanguageControlGroup } from '@xspot-app/common';
import { OfferVariantEntryDto } from './offer-variant-entry.dto';
import { OfferVariantProductDto } from './offer-variant-product.dto';

export interface OfferVariantDto {
  id: string;
  name: LanguageControlGroup;
  ordinal: number;
  entries: OfferVariantEntryDto[];
  products: OfferVariantProductDto[];
  information: LanguageControlGroup;
}
