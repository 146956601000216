import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@xspot-app/common';
import { Observable } from 'rxjs';
import { CreatePaymentResultDto } from '../dtos';
import { CreateBasketPaymentDto } from '../dtos/create-basket-payment.dto';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(private http: HttpClient) {}

  public createBasketPayment(
    payload: CreateBasketPaymentDto
  ): Observable<CreatePaymentResultDto> {
    return this.http.post<CreatePaymentResultDto>(
      `${environment.apiUrl}/api/payments/baskets`,
      {
        ...payload,
      }
    );
  }
}
