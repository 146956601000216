<ng-container>
  <div class="flex counter-field">
    <p-button
      (onClick)="decrement()"
      [disabled]="formControl.disabled"
      icon="pi pi-minus" />
    <p-inputNumber
      inputId="integeronly"
      [readonly]="true"
      [formControl]="formControl" />
    <p-button
      (onClick)="increment()"
      [disabled]="formControl.disabled"
      icon="pi pi-plus" />
  </div>
</ng-container>
