import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeroOrTwoDecimalDigitsPipe',
  standalone: true,
})
export class ZeroOrTwoDecimalDigitsPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  public transform(value?: number | string): string | null {
    if (value == null) return null;

    const returnValue = this.decimalPipe.transform(value, '1.2-2', 'pl-PL');

    if (returnValue?.endsWith(',00')) {
      return returnValue.replace(',00', '');
    } else {
      return returnValue;
    }
  }
}
