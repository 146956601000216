import { AbstractControl, FormGroup, FormArray } from '@angular/forms';

export function highlightFormErrors(control: AbstractControl): void {
  if (control instanceof FormGroup || control instanceof FormArray) {
    Object.values(control.controls).forEach(ctrl => {
      highlightFormErrors(ctrl);
    });
  } else {
    control.markAsDirty();
    control.markAsTouched();
    control.updateValueAndValidity({ emitEvent: true });
  }
}
