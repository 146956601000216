import { Injectable } from '@angular/core';
import { StateToken, State, Selector, Action, StateContext } from '@ngxs/store';
import { Observable, map, tap, withLatestFrom } from 'rxjs';
import { LocationsService } from '../services';
import { LocationsStateModel } from './locations.state-model';
import { CustomerLocationsListDto } from '../dtos';
import { Locations } from './locations.actions';
import { UiContextService } from '@xspot-app/common';

const LOCATIONS_STATE_TOKEN = new StateToken<LocationsStateModel>('locations');

@State<LocationsStateModel>({
  name: LOCATIONS_STATE_TOKEN,
  defaults: {
    all: [],
  },
})
@Injectable()
export class LocationsState {
  constructor(
    private locationsService: LocationsService,
    private uiContextService: UiContextService
  ) {}

  @Selector()
  public static all(state: LocationsStateModel): CustomerLocationsListDto[] {
    return state.all;
  }

  @Action(Locations.GetAll)
  public getAllLocations(
    ctx: StateContext<LocationsStateModel>
  ): Observable<CustomerLocationsListDto[]> {
    return this.locationsService.getLocationsList().pipe(
      withLatestFrom(this.uiContextService.currentContext$),
      map(([data, context]) => {
        const contextLowerCaseData = context.toLocaleLowerCase();
        return data.filter(
          location =>
            location.format.toLocaleLowerCase() === contextLowerCaseData
        );
      }),
      tap(data => {
        ctx.patchState({
          all: data,
        });
      })
    );
  }
}
