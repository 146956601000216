import { Injectable } from '@angular/core';
import { CheckoutState, OfferModel } from '../domain';
import { Nullable } from '@xspot-app/common';

@Injectable({
  providedIn: 'root',
})
export class ShoppingPathStateService {
  private readonly OFFER_FORM_KEY = 'offerFormData';
  private readonly CHECKOUT_KEY = 'checkoutData';

  public setOfferForm(offerForm: OfferModel): void {
    sessionStorage.setItem(this.OFFER_FORM_KEY, JSON.stringify(offerForm));
  }

  public resetOfferForm(): void {
    sessionStorage.removeItem(this.OFFER_FORM_KEY);
  }

  public getOfferModel(): Nullable<OfferModel> {
    const offerModelJson = sessionStorage.getItem(this.OFFER_FORM_KEY);

    if (!offerModelJson) return null;

    const offerModel = offerModelJson
      ? (JSON.parse(offerModelJson) as OfferModel)
      : null;
    if (offerModel) {
      offerModel.selectedDate = offerModel.selectedDate
        ? new Date(offerModel.selectedDate)
        : new Date();
    }
    return offerModel;
  }

  public setCheckoutState(state: CheckoutState): void {
    sessionStorage.setItem(this.CHECKOUT_KEY, JSON.stringify(state));
  }

  public resetCheckoutState(): void {
    sessionStorage.removeItem(this.CHECKOUT_KEY);
  }

  public getCheckoutState(): Nullable<CheckoutState> {
    const paymentStateJson = sessionStorage.getItem(this.CHECKOUT_KEY);

    return paymentStateJson
      ? (JSON.parse(paymentStateJson) as CheckoutState)
      : null;
  }
}
