import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { OffersStateModel } from './offers.state-model';
import { Injectable } from '@angular/core';
import { OffersService } from '../services';
import { Offers } from './offers.actions';
import { Observable, tap } from 'rxjs';
import { PagedList, pagedListDefaults } from '@xspot-app/common';
import {
  OfferListDto,
  OrderedOfferDto,
  OffersTreeOfferNodeDto,
  OfferDetailsDto,
  CreateOfferDto,
} from '../dtos';
import { OfferModel, OrderedOfferModel } from '../domain';
import { produce } from 'immer';
import { OfferDetailsModel } from '../domain/offer-details.model';
import { UpdateOfferDto } from '../dtos/update-offer-dto';

const OFFERS_STATE_TOKEN = new StateToken<OffersStateModel>('offers');

@State<OffersStateModel>({
  name: OFFERS_STATE_TOKEN,
  defaults: {
    all: pagedListDefaults,
    orderedList: [],
    isVariantDialogVisible: false,
    details: {},
    offersTree: [],
  },
})
@Injectable()
export class OffersState {
  constructor(private offersService: OffersService) {}

  @Selector()
  public static all(state: OffersStateModel): PagedList<OfferModel> {
    return state.all;
  }

  @Selector()
  public static offerDetail(state: OffersStateModel) {
    return (id: string) => {
      return state.details[id];
    };
  }

  @Selector()
  public static offersTree(state: OffersStateModel): OffersTreeOfferNodeDto[] {
    return state.offersTree;
  }

  @Action(Offers.GetById, { cancelUncompleted: true })
  public getById(
    ctx: StateContext<OffersStateModel>,
    action: Offers.GetById
  ): Observable<OfferDetailsModel> {
    return this.offersService.getById(action.payload.id).pipe(
      tap((response: OfferDetailsDto) => {
        const state = produce(ctx.getState(), draft => {
          draft.details[action.payload.id] = response;
        });

        ctx.setState(state);
      })
    );
  }

  @Selector()
  public static orderedList(state: OffersStateModel): OrderedOfferModel[] {
    return state.orderedList;
  }

  @Action(Offers.Add)
  public addOffers(
    ctx: StateContext<OffersStateModel>,
    { payload }: Offers.Add
  ): Observable<CreateOfferDto> {
    return this.offersService.addOffers(payload);
  }

  @Action(Offers.GetAll, { cancelUncompleted: true })
  public getAll(
    ctx: StateContext<OffersStateModel>,
    action: Offers.GetAll
  ): Observable<OfferListDto> {
    return this.offersService.getOfferList(action.payload).pipe(
      tap((response: OfferListDto): void => {
        const state: OffersStateModel = produce(ctx.getState(), draft => {
          draft.all = response;
        });

        ctx.setState(state);
      })
    );
  }

  @Action(Offers.GetOrderedList, { cancelUncompleted: true })
  public getOrderedList(
    ctx: StateContext<OffersStateModel>
  ): Observable<OrderedOfferModel[]> {
    return this.offersService.getOrderedOfferList().pipe(
      tap((response: OrderedOfferDto[]): void => {
        const state: OffersStateModel = produce(ctx.getState(), draft => {
          draft.orderedList = response;
        });

        ctx.setState(state);
      })
    );
  }

  @Action(Offers.Delete)
  public deleteOffer(
    ctx: StateContext<OffersStateModel>,
    { id }: Offers.Delete
  ): Observable<void> {
    return this.offersService.deleteOffer(id);
  }

  @Action(Offers.Update)
  public updateOffers(
    ctx: StateContext<UpdateOfferDto>,
    { payload }: Offers.Update
  ): Observable<UpdateOfferDto> {
    return this.offersService.updateOffer(payload);
  }

  @Action(Offers.OpenVariantDialog)
  public openVariantDialog(ctx: StateContext<OffersStateModel>): void {
    ctx.patchState({ isVariantDialogVisible: true });
  }

  @Action(Offers.CloseVariantDialog)
  public closeVariantDialog(ctx: StateContext<OffersStateModel>): void {
    ctx.patchState({ isVariantDialogVisible: false });
  }

  @Action(Offers.Activate)
  public activateOffer(
    ctx: StateContext<OffersStateModel>,
    { id }: Offers.Activate
  ): Observable<void> {
    return this.offersService.activate(id);
  }

  @Action(Offers.Deactivate)
  public deactivateOffer(
    ctx: StateContext<OffersStateModel>,
    { id }: Offers.Deactivate
  ): Observable<void> {
    return this.offersService.deactivate(id);
  }

  @Action(Offers.GetOffersTree)
  public getOffersTree(
    ctx: StateContext<OffersStateModel>
  ): Observable<OffersTreeOfferNodeDto[]> {
    return this.offersService.getOffersTree().pipe(
      tap((response: OffersTreeOfferNodeDto[]): void => {
        const state: OffersStateModel = produce(ctx.getState(), draft => {
          draft.offersTree = response;
        });

        ctx.setState(state);
      })
    );
  }

  @Action(Offers.UpdateOffersOrder)
  public updateOffersOrder(
    ctx: StateContext<OffersStateModel>,
    { payload }: Offers.UpdateOffersOrder
  ): Observable<void> {
    return this.offersService.updateOffersOrder(payload);
  }
}
