import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetTranslationDto } from '../dtos/get-translation.dto';
import { LanguageControlGroup } from '../dtos';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  constructor(private http: HttpClient) {}

  public translate(
    payload: GetTranslationDto
  ): Observable<LanguageControlGroup> {
    return this.http.post<LanguageControlGroup>(
      `${environment.apiUrl}/backoffice/translations`,
      { ...payload }
    );
  }
}
