import { Component, Input, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { OverlayPanel } from 'primeng/overlaypanel';
import { CommonModule } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { AvatarModule } from 'primeng/avatar';
import { MenuType } from '../../enums';
import { MenuTopBar } from '../../models';

@Component({
  selector: 'xspot-app-drop-menu',
  standalone: true,
  imports: [CommonModule, AvatarModule, ButtonModule, MenuModule],
  templateUrl: './drop-menu.component.html',
  styleUrls: ['./drop-menu.component.scss'],
})
export class DropMenuComponent {
  @Input({ required: true }) public items!: MenuTopBar[];
  @ViewChild('overlayMenu') public overlayMenu!: OverlayPanel;

  public menuType = MenuType;

  public toggle(event: Event): void {
    this.overlayMenu.toggle(event);
  }
}
