import { AbstractControl } from '@angular/forms';
import { PhoneNumber } from '../models';

export function PhoneNumberWithPrefixRequiredValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const { prefix, number }: PhoneNumber = control.value;

  if (prefix && number) {
    return null;
  }

  return { phoneNumberWithPrefixRequired: true };
}
