import {
  Nullable,
  OfferProductPriceKindEnum,
  OfferProductFormEnum,
} from '@xspot-app/common';

export interface CalculateVariantPriceProductDto {
  isMinimalAmount: Nullable<boolean | undefined>;
  productForm: OfferProductFormEnum;
  startAmount: Nullable<number | undefined>;
  amount: Nullable<number | undefined>;
  priceKind: OfferProductPriceKindEnum;
  singlePrice: Nullable<number | undefined>;
  segmentPrices: number[] | undefined;
}
