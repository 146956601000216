import { ExternalProviders } from '@xspot-app/shared/auth';

export interface ExternalProviderModel {
  provider: ExternalProviders;
  accessToken: string;
  email: string;
  birthday: string | null;
  last_name: string;
  first_name: string;
}
