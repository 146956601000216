import {
  LanguageControlGroup,
  Nullable,
  OfferCategoryEnum,
  OfferMediaKindEnum,
  OfferProductPriceKindEnum,
  OfferProductFormEnum,
  SlotKindEnum,
  ProductKindEnum,
  OfferKindEnum,
} from '@xspot-app/common';

export interface CreateOfferDto {
  coverMobileId: string;
  coverDesktopId: string;
  isHidden: boolean;
  name: LanguageControlGroup;
  teaser: LanguageControlGroup;
  description: LanguageControlGroup;
  kind: OfferKindEnum;
  limitedOffersNumber: Nullable<number>;
  maxOffersPerClient: Nullable<number>;
  legalContent: LanguageControlGroup;
  locationIds: string[];
  media: CreateOfferMediaDto[];
  legalFiles: CreateOfferLegalFileDto[];
  variants: CreateOfferVariantDto[];
  upsellIds: string[];
  categories: OfferCategoryEnum[];
}

export interface CreateOfferMediaDto {
  fileId: Nullable<string>;
  movieUri: Nullable<string>;
  kind: OfferMediaKindEnum;
  ordinal: number;
}

export interface CreateOfferLegalFileDto {
  fileId: string;
  ordinal: number;
}

export interface CreateOfferVariantDto {
  publicationStartDate: string;
  publicationEndDate: string;
  name: LanguageControlGroup;
  information: LanguageControlGroup;
  ordinal: number;
  entries: CreateOfferVariantEntryDto[];
  products: CreateOfferVariantProductDto[];
}

export interface CreateOfferVariantEntryDto {
  header: LanguageControlGroup;
  description: LanguageControlGroup;
  ordinal: number;
}

export interface CreateOfferVariantProductDto {
  productId: string;
  kind: ProductKindEnum;
  isMinimalAmount: Nullable<boolean>;
  ordinal: number;
  productForm: OfferProductFormEnum;
  minAmount: Nullable<number>;
  maxAmount: Nullable<number>;
  startAmount: Nullable<number>;
  amount: Nullable<number>;
  priceKind: OfferProductPriceKindEnum;
  singlePrice: Nullable<number>;
  segmentPrices: CreateOfferVariantProductSegmentPriceDto[];
}

export interface CreateOfferVariantProductSegmentPriceDto {
  slotKind: SlotKindEnum;
  price: number;
}
