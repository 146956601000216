<ng-container *transloco="let t">
  <div class="fb-login-button" (click)="logInWithFb()">
    <p-button
      [loading]="loading"
      [label]="t('loginForm.fb')"
      icon="pi pi-facebook"
      [outlined]="true"
      severity="secondary"></p-button>
  </div>
</ng-container>
