import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class SessionIdService {
  private readonly SESSION_ID_KEY = 'sessionId';

  public sessionId$ = new BehaviorSubject<string>(
    localStorage.getItem(this.SESSION_ID_KEY) ?? ''
  );

  constructor() {
    this.initializeSessionId();
  }

  public initializeSessionId(): string {
    const sessionId = localStorage.getItem(this.SESSION_ID_KEY);

    if (!sessionId) {
      const newSessionId = uuidv4();
      localStorage.setItem(this.SESSION_ID_KEY, newSessionId);
      this.sessionId$.next(newSessionId);

      return newSessionId;
    }

    return sessionId;
  }

  public clearSessionId(): void {
    localStorage.removeItem(this.SESSION_ID_KEY);
    this.sessionId$.next('');
  }
}
