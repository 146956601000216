<ng-container *transloco="let t">
  <form
    class="flex flex-column gap-4"
    [formGroup]="loginForm"
    (submit)="submitForm()">
    <div class="form-control">
      <span class="p-float-label">
        <input
          class="w-full"
          pInputText
          id="email"
          formControlName="email"
          autocomplete="email"
          type="email" />
        <label htmlFor="email">{{ t('loginForm.email') }}</label>
      </span>
      <ng-container
        *ngIf="emailControl.touched && emailControl.errors as errors">
        <small class="text-error" *ngIf="errors['required']">{{
          t('common.form.emailRequired')
        }}</small>
        <small class="text-error" *ngIf="errors['invalidEmail']">{{
          t('common.form.emailInvalid')
        }}</small>
      </ng-container>
    </div>
    <div class="form-control">
      <span class="p-float-label">
        <p-password
          styleClass="w-full"
          inputStyleClass="w-full"
          id="password"
          formControlName="password"
          autocomplete="current-password"
          [feedback]="false"
          [toggleMask]="true"></p-password>
        <label htmlFor="password">{{ t('loginForm.password') }}</label>
      </span>
      <ng-container
        *ngIf="passwordControl.touched && passwordControl.errors as errors">
        <small class="text-error" *ngIf="errors['required']">{{
          t('common.form.passwordRequired')
        }}</small>
      </ng-container>
    </div>
    <a
      class="align-self-end text-primary"
      routerLink="reset-password"
      [queryParamsHandling]="'preserve'">
      {{ t('loginForm.forgetPassword') }}
    </a>
    <p-button
      styleClass="w-full"
      type="submit"
      [loading]="loading"
      [label]="t('loginForm.login')"></p-button>
  </form>
</ng-container>
