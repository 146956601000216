import {
  LanguageControlGroup,
  Nullable,
  OfferMediaKindEnum,
  OfferProductFormEnum,
  OfferProductPriceKindEnum,
  ProductKindEnum,
  SlotKindEnum,
  VolumePriceDiscountKindEnum,
} from '@xspot-app/common';
import { LocationsDto } from '@xspot-app/shared/locations';

export interface CustomerOfferDto {
  id: string;
  name: LanguageControlGroup;
  teaser: LanguageControlGroup;
  description: LanguageControlGroup;
  legalContent: LanguageControlGroup;
  locations: LocationsDto;
  media: CustomerOfferMediaDto[];
  legalFiles: CustomerOfferLegalFileDto[];
  variants: CustomerOfferVariantDto[];
  hasUpsells: boolean;
}

export interface CustomerOfferMediaDto {
  kind: OfferMediaKindEnum;
  uri: string;
  Ordinal: number;
}

export interface CustomerOfferLegalFileDto {
  uri: string;
  fileName: string;
  ordinal: number;
}

export interface CustomerOfferVariantDto {
  id: string;
  name: LanguageControlGroup;
  ordinal: number;
  entries: CustomerOfferVariantEntryDto[];
  products: CustomerOfferVariantProductDto[];
  information: LanguageControlGroup;
}

export interface CustomerOfferVariantEntryDto {
  header: LanguageControlGroup;
  description: LanguageControlGroup;
  ordinal: number;
}

export interface CustomerOfferVariantProductDto {
  id: string;
  isMinimalAmount: Nullable<boolean>;
  productKind: ProductKindEnum;
  displayName: LanguageControlGroup;
  productForm: OfferProductFormEnum;
  minAmount?: Nullable<number>;
  maxAmount?: Nullable<number>;
  startAmount?: Nullable<number>;
  amount?: Nullable<number>;
  ordinal: number;
  priceKind: OfferProductPriceKindEnum;
  singlePrice: Nullable<number>;
  volumePrice: Nullable<CustomerOfferVariantProductVolumePriceDto>;
  segmentPrices: CustomerOfferVariantProductSegmentPriceDto[];
}

export interface CustomerOfferVariantProductSegmentPriceDto {
  price: number;
  slotKind: SlotKindEnum;
}

export interface CustomerOfferVariantProductVolumePriceDto {
  id: string;
  discountKind: VolumePriceDiscountKindEnum;
  items: CustomerOfferVariantProductVolumePriceItemDto[];
}

export interface CustomerOfferVariantProductVolumePriceItemDto {
  from: number;
  discountValue: number;
}
