import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[isItemSelected]',
  standalone: true,
})
export class IsItemSelectedDirective implements OnChanges {
  @Input() public isItemSelected: boolean = false;

  private selectedElement: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.selectedElement = this.renderer.createElement('div');
    this.renderer.addClass(this.selectedElement, 'bg-primary');
    this.renderer.addClass(this.selectedElement, 'absolute');
    this.renderer.addClass(this.selectedElement, 'top-0');
    this.renderer.addClass(this.selectedElement, 'left-0');
    this.renderer.addClass(this.selectedElement, 'is-item-selected__wrapper');

    const icon = this.renderer.createElement('i');
    this.renderer.addClass(icon, 'pi');
    this.renderer.addClass(icon, 'pi-check');
    this.renderer.addClass(icon, 'is-item-selected__icon');
    this.renderer.appendChild(this.selectedElement, icon);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['isItemSelected']) {
      this.updateSelectedState();
    }
  }

  private updateSelectedState(): void {
    const className = 'is-item-selected';
    if (this.isItemSelected) {
      this.renderer.addClass(this.el.nativeElement, className);
      this.renderer.appendChild(this.el.nativeElement, this.selectedElement);
    } else {
      this.renderer.removeClass(this.el.nativeElement, className);
      if (this.el.nativeElement.contains(this.selectedElement)) {
        this.renderer.removeChild(this.el.nativeElement, this.selectedElement);
      }
    }
  }
}
