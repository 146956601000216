import { Time } from '@angular/common';

export function isTimeGreater(value: Time, compareTo: Time): boolean {
  const totalMinutesValue = value.hours * 60 + value.minutes;
  const totalMinutesCompareTo = compareTo.hours * 60 + compareTo.minutes;
  return totalMinutesValue > totalMinutesCompareTo;
}

export function parseToTime(value: string): Time {
  const [hour, minute] = value.split(':').map(Number);
  return {
    hours: hour,
    minutes: minute,
  };
}
