import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { VariantProductForm } from './variant-product-form.model';
import { VariantEntryForm } from './variant-entry-form.model';
import { VariantPrice } from './variant-price.model';
import { Nullable } from '@xspot-app/common';

export interface VariantForm {
  id?: FormControl<Nullable<string>>;
  ordinal?: FormControl<Nullable<number>>;
  publicationStartDate: FormControl<Nullable<Date>>;
  publicationEndDate: FormControl<Nullable<Date>>;
  nameGroup: FormGroup<Record<string, FormControl<string>>>;
  informationGroup: FormGroup<Record<string, FormControl<string>>>;
  productIds: FormControl<string[]>;
  products: FormArray<FormGroup<VariantProductForm>>;
  entries: FormArray<FormGroup<VariantEntryForm>>;
  price: FormControl<Nullable<VariantPrice>>;
}
