import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[dragAndDrop]',
  standalone: true,
})
export class DragAndDropDirective {
  @Output() public fileDropped = new EventEmitter<FileList>();
  @Output() public dragOver = new EventEmitter<void>();
  @Output() public dragLeave = new EventEmitter<void>();

  @HostListener('dragover', ['$event']) public onDragOver(
    event: DragEvent
  ): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver.emit();
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(
    event: DragEvent
  ): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragLeave.emit();
  }

  @HostListener('drop', ['$event']) public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
