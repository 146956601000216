import { LanguageControlGroup, Nullable } from '@xspot-app/common';

export interface BasketModel {
  totalPrice: number;
  offers: BasketOfferModel[];
}

export interface BasketOfferModel {
  id: string;
  offerId: string;
  offerVariantId: string;
  totalPrice: number;
  name: LanguageControlGroup;
  variantName: LanguageControlGroup;
  locationName: LanguageControlGroup;
  locationId: string;
  products: BasketOfferProductModel[];
  reservationProducts: BasketOfferReservationProductModel[];
  upsells: BasketOfferUpsellModel[];
  detailsVisibility: boolean;
  coverDesktopUri: string;
  coverMobileUri: string;
}

export interface BasketOfferProductModel {
  offerVariantProductId: string;
  totalPrice: number;
  actualPrice: number;
  name: LanguageControlGroup;
  quantity: number;
}

export interface BasketOfferUpsellModel {
  upsellId: string;
  totalPrice: number;
  name: LanguageControlGroup;
  quantity: number;
}

export interface BasketOfferReservationProductModel {
  date: string;
  slots: BasketOfferSlotModel[];
  isReservationPro: boolean;
}

export interface BasketOfferSlotModel {
  timeFrom: string;
  timeTo: string;
  products: BasketOfferProductModel[];
  minutes: Nullable<number>;
}
