import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function rangeValidator(
  min: string,
  max: string,
  value: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const minControl = control.get(min);
    const maxControl = control.get(max);
    const valueControl = control.get(value);

    if (
      minControl &&
      maxControl &&
      (minControl.value || minControl.value === 0) &&
      (maxControl.value || maxControl.value === 0)
    ) {
      if (Number(minControl.value) > Number(maxControl.value)) {
        return { minGreaterThanMax: true };
      }

      if (
        valueControl &&
        (valueControl.value || valueControl.value === 0) &&
        (Number(valueControl.value) < Number(minControl.value) ||
          Number(valueControl.value) > Number(maxControl.value))
      ) {
        return { outOfRange: true };
      }
    }

    return null;
  };
}
