import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { NotificationService } from '../services';
import { TranslocoService } from '@ngneat/transloco';
import { ErrorUtil } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private notificationService: NotificationService,
    private translocoService: TranslocoService
  ) {}

  public handleError(error?: Error): void {
    if (ErrorUtil.isUnauthorizedOrInvalidRefreshTokenError(error)) {
      return;
    }

    let errorNotificationToken: string = 'notification.error.processingError';

    if (
      error instanceof HttpErrorResponse &&
      (error.status === 0 || error.status >= 500)
    ) {
      errorNotificationToken = 'notification.error.serverErrorResponse';
    } else if (error instanceof HttpErrorResponse && error.error.errorCode) {
      const token = 'notification.error.' + error.error.errorCode;
      if (this.translocoService.translate(token) !== token) {
        errorNotificationToken = token;
      }
    } else {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    this.notificationService.showErrorNotification(errorNotificationToken);
  }
}
