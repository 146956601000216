import {
  GetOfferListDto,
  GetOfferDto,
  UpdateOfferOrderDto,
  CreateOfferDto,
} from '../dtos';
import { UpdateOfferDto } from '../dtos/update-offer-dto';

export namespace Offers {
  export class Add {
    public static readonly type = '[Offers] Add';
    constructor(public payload: CreateOfferDto) {}
  }

  export class GetAll {
    public static readonly type = '[Offers] GetAll';
    constructor(public payload: GetOfferListDto) {}
  }

  export class Update {
    public static readonly type = '[Offers] Update';
    constructor(public payload: UpdateOfferDto) {}
  }

  export class GetById {
    public static readonly type = '[Offers] Get By Id';
    constructor(public payload: GetOfferDto) {}
  }

  export class GetOrderedList {
    public static readonly type = '[Offers] GetOrderedList';
  }

  export class OpenVariantDialog {
    public static readonly type = '[Offers] Open Variant Dialog';
  }

  export class CloseVariantDialog {
    public static readonly type = '[Offers] Close Variant Dialog';
  }

  export class Delete {
    public static readonly type = '[Offers] Delete';
    constructor(public id: string) {}
  }

  export class Activate {
    public static readonly type = '[Offers] Activate';
    constructor(public id: string) {}
  }

  export class Deactivate {
    public static readonly type = '[Offers] Deactivate';
    constructor(public id: string) {}
  }

  export class GetOffersTree {
    public static readonly type = '[Offers] Get Offers Tree';
  }

  export class UpdateOffersOrder {
    public static readonly type = '[Offers] UpdateOffersOrder';
    constructor(public payload: UpdateOfferOrderDto[]) {}
  }
}
