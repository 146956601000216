import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import {
  formTimeFormat,
  formTimeFormatWithSeconds,
} from './../consts/date-time.const';

@Pipe({
  name: 'time',
  standalone: true,
})
export class TimePipe implements PipeTransform {
  public transform(
    value: string | { hours: number; minutes: number },
    seconds: boolean = false
  ): string {
    let time: DateTime;

    if (typeof value === 'string') {
      time = DateTime.fromFormat(value, formTimeFormatWithSeconds).isValid
        ? DateTime.fromFormat(value, formTimeFormatWithSeconds)
        : DateTime.fromFormat(value, formTimeFormat);
    } else {
      time = DateTime.fromObject({ hour: value.hours, minute: value.minutes });
    }

    if (!time || !time.isValid) {
      return '';
    }

    const formatString = seconds ? formTimeFormatWithSeconds : formTimeFormat;
    return time.toFormat(formatString);
  }
}
