import { PhoneNumber } from './phone-number.model';
import { Language } from '../enums';

export interface ProfileModel {
  name: string;
  surname: string;
  password: string;
  phoneNumber: PhoneNumber;
  dateOfBirth: string | null;
  language: Language;
}
