<ng-container *transloco="let t">
  <span class="p-float-label">
    <p-password
      styleClass="w-full"
      inputStyleClass="w-full"
      autocomplete="new-password"
      id="password"
      [promptLabel]="t('password.enterPassword')"
      [formControl]="passwordControl"
      [toggleMask]="true"
      [weakLabel]="t('password.weakLabel')"
      [mediumLabel]="t('password.mediumLabel')"
      [strongLabel]="t('password.strongLabel')">
      <ng-template pTemplate="footer">
        <p-divider></p-divider>
        <p class="mt-2">{{ t('password.passwordMustContain') }}</p>
        <ul class="pl-3 ml-2 mt-0" style="line-height: 1.5">
          <li
            [validationTextColor]="errors?.['tooShort'] || errors?.['tooLong']">
            {{ t('password.minMaxLength') }}
          </li>
          <li [validationTextColor]="errors?.['requireDigit']">
            {{ t('password.atLeastOneDigit') }}
          </li>
          <li [validationTextColor]="errors?.['requireUpperCaseLetter']">
            {{ t('password.atLeastOneUpperCaseLetter') }}
          </li>
          <li [validationTextColor]="errors?.['requireLowerCaseLetter']">
            {{ t('password.atLeastOneLowerCaseLetter') }}
          </li>
          <li [validationTextColor]="errors?.['forbiddenWhitespace']">
            {{ t('password.noWhiteSpaces') }}
          </li>
          <li [validationTextColor]="errors?.['requireSpecialCharacter']">
            {{ t('password.specialCharacters') }}
          </li>
          <li [validationTextColor]="errors?.['forbiddenCharacter']">
            {{ t('password.forbiddenCharacter') }}
          </li>
        </ul>
      </ng-template>
    </p-password>
    <label htmlFor="password">{{ t('password.password') }}</label>
  </span>
</ng-container>
