import { Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '../helpers';

@Pipe({
  name: 'placeholder',
  standalone: true,
})
export class PlaceholderPipe implements PipeTransform {
  public transform(value: Nullable<string>, sign: string = '-'): string {
    const hasText = !!(value && value.trim().length > 0);
    return hasText ? value : sign;
  }
}
