import {
  AgeGroupKindEnum,
  CreditKindEnum,
  LanguageControlGroup,
  Nullable,
  OfferCategoryEnum,
  OfferMediaKindEnum,
  OfferProductPriceKindEnum,
  OfferProductFormEnum,
  RelatedLocationDto,
  RelatedUpsellDto,
  SlotKindEnum,
  OfferKindEnum,
} from '@xspot-app/common';
import { OfferStatusEnum } from '../enums';
import { PriceDto } from '../dtos';
import { VolumePriceDiscountKindEnum } from '@xspot-app/back-office/volume-price-api';

export interface OfferDetailsModel {
  id: string;
  coverMobileUri: string;
  coverDesktopUri: string;
  isHidden: boolean;
  name: LanguageControlGroup;
  teaser: LanguageControlGroup;
  description: LanguageControlGroup;
  kind: OfferKindEnum;
  limitedOffersNumber: Nullable<number>;
  maxOffersPerClient: Nullable<number>;
  legalContent: LanguageControlGroup;
  status: OfferStatusEnum;
  locations: RelatedLocationDto[];
  media: OfferMediaModel[];
  legalFiles: OfferLegalFileModel[];
  variants: OfferVariantModel[];
  upsells: RelatedUpsellDto[];
  categories: OfferCategoryEnum[];
}

export interface OfferMediaModel {
  id: string;
  kind: OfferMediaKindEnum;
  uri: string;
  fileName: string;
  ordinal: number;
}

export interface OfferLegalFileModel {
  id: string;
  fileName: string;
  uri: string;
  ordinal: number;
}

export interface OfferVariantModel {
  id: string;
  publicationStartDate: string;
  publicationEndDate: string;
  name: LanguageControlGroup;
  information: LanguageControlGroup;
  ordinal: number;
  priceDto: PriceDto;
  entries: OfferVariantEntryModel[];
  products: OfferVariantProductModel[];
}

export interface OfferVariantEntryModel {
  id: string;
  header: LanguageControlGroup;
  description: LanguageControlGroup;
  ordinal: number;
}

export interface OfferVariantProductModel {
  id: string;
  productId: string;
  reservationDetails: Nullable<OfferVariantReservationProductModel>;
  creditDetails: Nullable<OfferVariantCreditProductModel>;
  supplementDetails: Nullable<OfferVariantSupplementProductModel>;
  productForm: OfferProductFormEnum;
  minAmount: Nullable<number>;
  maxAmount: Nullable<number>;
  startAmount: Nullable<number>;
  amount: Nullable<number>;
  ordinal: number;
  priceKind: OfferProductPriceKindEnum;
  singlePrice: Nullable<number>;
  volumePrice: Nullable<OfferVariantProductVolumePriceModel>;
  segmentPrices: OfferVariantProductSegmentPriceModel[];
}

export interface OfferVariantReservationProductModel {
  productName: string;
  isMinimalAmount: boolean;
  amount: number;
  ageGroupKind: AgeGroupKindEnum;
}

export interface OfferVariantCreditProductModel {
  productName: string;
  creditKind: CreditKindEnum;
  expireAt: Nullable<string>;
  expireInMonths: Nullable<number>;
  amount: number;
}

export interface OfferVariantSupplementProductModel {
  productName: string;
}

export interface OfferVariantProductSegmentPriceModel {
  slotKind: SlotKindEnum;
  price: number;
}

export interface OfferVariantProductVolumePriceModel {
  id: string;
  name: string;
  discountKind: VolumePriceDiscountKindEnum;
  items: OfferVariantProductVolumePriceItemModel[];
}

export interface OfferVariantProductVolumePriceItemModel {
  from: number;
  discountValue: number;
}
