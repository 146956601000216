import { AbstractControl } from '@angular/forms';
import { DateTime } from 'luxon';
import { formDateFormat } from '../consts';

export function DateFormatValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const inputValue: string = control.value;

  // Check if the input value is empty or null
  if (!inputValue) {
    return null; // Return null for empty values
  }

  // Validate the date using Luxon
  const parsedDate = DateTime.fromFormat(inputValue, formDateFormat);

  if (!parsedDate.isValid) {
    return { invalidDateFormat: true }; // Return an error if the date format is invalid
  }

  return null; // Return null for valid dates
}
