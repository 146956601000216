import { SlotRestrictedOfferKindEnum } from '@xspot-app/common';
import { OfferStatusEnum } from '../enums';

export interface OrderedOfferDto {
  id: string;
  name: string;
  kind: SlotRestrictedOfferKindEnum;
  variantsCount: number;
  status: OfferStatusEnum;
  ordinal: number;
}
