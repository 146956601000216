import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionIdService } from '../services';

export function sessionInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const sessionIdSevice = inject(SessionIdService);
  const sessionId = sessionIdSevice.sessionId$.getValue();

  if (!sessionId) {
    return next(request);
  }

  const modifiedRequest = request.clone({
    setHeaders: {
      sessionId: sessionId,
    },
  });

  return next(modifiedRequest);
}
