import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@xspot-app/common';
import { BasketDto, BasketItemDto } from '../dtos';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BasketsService {
  constructor(private http: HttpClient) {}

  public addItem(payload: BasketItemDto): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/api/baskets/items`, {
      ...payload,
    });
  }

  public updateItem(payload: BasketItemDto): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}/api/baskets/items/${payload.id}`,
      payload
    );
  }

  public getItems(): Observable<BasketDto> {
    return this.http.get<BasketDto>(`${environment.apiUrl}/api/baskets`);
  }

  public deleteItem(id: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiUrl}/api/baskets/items/${id}`
    );
  }
}
