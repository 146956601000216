import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from '@xspot-app/customer/auth';
import { UserState } from '@xspot-app/customer/users';
import {
  BasketsState,
  OffersState,
  PaymentsState,
} from '@xspot-app/customer/shopping-path';
import { LocationsState } from '@xspot-app/customer/locations';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { provideTransloco } from '@ngneat/transloco';
import {
  AUTH_INTERCEPTOR_CONFIG,
  environment,
  GlobalErrorHandler,
  SessionIdService,
  sessionInterceptor,
  TranslocoHttpLoader,
  UiContextService,
  ZeroOrTwoDecimalDigitsPipe,
} from '@xspot-app/common';
import { AuthInterceptor } from '@xspot-app/shared/auth';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import {
  AvailableCustomerLanguages,
  contextInterceptor,
  defaultLanguage,
  CurrencyState,
} from '@xspot-app/shared/ui';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';
import { MessageService } from 'primeng/api';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { VoucherState } from '@xspot-app/customer/voucher';
import { provideOAuthClient } from 'angular-oauth2-oidc';

registerLocaleData(localePl, 'pl');

export const appConfig: ApplicationConfig = {
  providers: [
    DecimalPipe,
    ZeroOrTwoDecimalDigitsPipe,
    UiContextService,
    SessionIdService,
    {
      provide: AUTH_INTERCEPTOR_CONFIG,
      useValue: {
        apiEndpointForRefreshToken: `${environment.apiUrl}/api/authorization/RefreshToken`,
      },
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    MessageService,
    provideRouter(appRoutes),
    provideHttpClient(
      withInterceptors([contextInterceptor, sessionInterceptor]),
      withInterceptorsFromDi()
    ),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideRouter(appRoutes),
    provideOAuthClient(),
    provideAnimations(),
    importProvidersFrom(
      NgxsModule.forRoot(
        [
          AuthState,
          UserState,
          CurrencyState,
          OffersState,
          PaymentsState,
          LocationsState,
          BasketsState,
          VoucherState,
        ],
        {
          developmentMode: isDevMode(),
        }
      ),
      NgxsResetPluginModule.forRoot(),
      NgxsActionsExecutingModule.forRoot(),
      // devtools always last
      NgxsReduxDevtoolsPluginModule.forRoot()
    ),
    provideTransloco({
      config: {
        availableLangs: Object.values(AvailableCustomerLanguages),
        defaultLang:
          localStorage.getItem('selectedLanguage') || defaultLanguage,
        fallbackLang: defaultLanguage,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideEnvironmentNgxMask(),
  ],
};
