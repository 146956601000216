import { CustomerOfferVariantModel } from './customer-offer.model';
import { LocationControlModel } from './location-control.model';
import { SelectedSlot } from './selected-slot.model';
import { Nullable } from '@xspot-app/common';

export interface OfferModel {
  id: string;
  activeIndex: number;
  location?: Nullable<LocationControlModel>;
  variant?: Nullable<CustomerOfferVariantModel>;
  variantProducts?: Record<string, number>;
  upsells?: Record<string, number>;
  selectedDate?: Nullable<Date>;
  selectedSlots?: SelectedSlot[];
}
