import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'toCamelCase',
})
export class ToCamelCasePipe implements PipeTransform {
  private searchPattern: RegExp = /^\w|[A-Z]|\b\w/g;

  public transform(value: string): string {
    if (!value) throw new Error('Value is required');
    return value.trim().replace(this.searchPattern, this.replacer);
  }

  private replacer(match: string, index: number): string {
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  }
}
