// TODO: change codes for Ukrainian and Czech languages after backend fix FLYS-1953
export const LanguagePropertyNames = {
  PL: 'pl',
  // CS: 'cs',
  CZ: 'cz',
  DE: 'de',
  EN: 'en',
  ES: 'es',
  FR: 'fr',
  // UK: 'uk',
  UA: 'ua',
};
