import { Route } from '@angular/router';
import { provideTranslocoScope } from '@ngneat/transloco';
import { importProvidersFrom, inject } from '@angular/core';
import { AuthService, StringUtil } from '@xspot-app/common';
import { OffersState } from '@xspot-app/customer/shopping-path';
import { NgxsModule } from '@ngxs/store';
import { LocationsState } from '@xspot-app/customer/locations';
import { UserState } from '@xspot-app/customer/users';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.routes').then(m => m.authRoutes),
    providers: [
      provideTranslocoScope('auth'),
      provideTranslocoScope('common'),
      provideTranslocoScope('notification'),
    ],
  },
  {
    path: 'profile',
    canMatch: [() => inject(AuthService).isAuthenticated()],
    loadComponent: () =>
      import('./pages/profile-container/profile-container.component').then(
        m => m.ProfileContainerComponent
      ),
    children: [
      {
        path: 'agreements',
        loadComponent: () =>
          import('./pages/agreements/agreements.component').then(
            m => m.AgreementsComponent
          ),
        providers: [
          provideTranslocoScope('common'),
          provideTranslocoScope('agreements'),
          provideTranslocoScope('notification'),
        ],
      },
      {
        path: 'user-profile',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/user-profile/user-profile.component').then(
            m => m.UserProfileComponent
          ),
        providers: [
          provideTranslocoScope('common'),
          provideTranslocoScope('user-profile'),
          provideTranslocoScope('notification'),
        ],
      },
      {
        path: StringUtil.empty,
        pathMatch: 'full',
        redirectTo: 'user-profile',
      },
    ],
  },
  {
    path: StringUtil.empty,
    loadComponent: () =>
      import('./pages/home/home.component').then(m => m.HomeComponent),
    providers: [provideTranslocoScope({ scope: 'common' })],
    children: [
      {
        path: StringUtil.empty,
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/landing-page/landing-page.component').then(
            m => m.LandingPageComponent
          ),
      },
      {
        path: 'offers',
        children: [
          {
            path: StringUtil.empty,
            loadComponent: () =>
              import(
                './pages/shopping-path/offers-list/offers-list.component'
              ).then(m => m.OffersListComponent),
          },
          {
            path: ':offerId/:selectedLocationId',
            loadComponent: () =>
              import(
                './pages/shopping-path/offer-details/offer-details.component'
              ).then(m => m.OfferDetailsComponent),
            providers: [
              provideTranslocoScope({ scope: 'offers' }),
              provideTranslocoScope({ scope: 'calendar' }),
              provideTranslocoScope({ scope: 'notification' }),
            ], // Ensure scope is provided here
          },
          {
            path: ':offerId',
            loadComponent: () =>
              import(
                './pages/shopping-path/offer-details/offer-details.component'
              ).then(m => m.OfferDetailsComponent),
            providers: [
              provideTranslocoScope({ scope: 'offers' }),
              provideTranslocoScope({ scope: 'calendar' }),
              provideTranslocoScope({ scope: 'notification' }),
            ], // Ensure scope is provided here
          },
          {
            path: ':offerId/edit/:basketItemId',
            loadComponent: () =>
              import(
                './pages/shopping-path/offer-details/offer-details.component'
              ).then(m => m.OfferDetailsComponent),
            providers: [
              provideTranslocoScope({ scope: 'offers' }),
              provideTranslocoScope({ scope: 'calendar' }),
              provideTranslocoScope({ scope: 'notification' }),
            ],
          },
        ],
        providers: [
          provideTranslocoScope({ scope: 'offers' }),
          provideTranslocoScope({ scope: 'notification' }),
          importProvidersFrom(
            NgxsModule.forFeature([OffersState, LocationsState])
          ),
        ],
      },
      {
        path: 'checkout',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/shopping-path/checkout/checkout.component').then(
            m => m.CheckoutComponent
          ),
        providers: [
          provideTranslocoScope({ scope: 'checkout' }),
          provideTranslocoScope('common'),
          provideTranslocoScope('notification'),
          importProvidersFrom(NgxsModule.forFeature([UserState])),
        ],
      },
    ],
  },
  {
    path: 'payments',
    loadChildren: () =>
      import('./pages/payments/payments.routes').then(m => m.paymentsRoutes),
    providers: [provideTranslocoScope({ scope: 'payments' })],
  },
  {
    path: '**',
    redirectTo: StringUtil.empty,
  },
];
