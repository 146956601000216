import { AbstractControl, ValidationErrors } from '@angular/forms';

export function PasswordValidator(
  control: AbstractControl
): ValidationErrors | null {
  const password = control.value as string;
  const errorList: { [key: string]: boolean } = {};

  const lowercaseRegex = /[a-z]/;
  const uppercaseRegex = /[A-Z]/;
  const numberRegex = /[0-9]/;
  const specialCharRegex = /[!@#$%^&*()]/;
  const whitespaceRegex = /\s/;
  const allowedCharacterRegex = /^[a-zA-Z0-9!@#$%^&*()]*$/;

  if (password.length < 8) errorList['tooShort'] = true;

  if (password.length > 50) errorList['tooLong'] = true;

  if (!lowercaseRegex.test(password))
    errorList['requireLowerCaseLetter'] = true;

  if (!uppercaseRegex.test(password))
    errorList['requireUpperCaseLetter'] = true;

  if (!numberRegex.test(password)) errorList['requireDigit'] = true;

  if (!specialCharRegex.test(password))
    errorList['requireSpecialCharacter'] = true;

  if (whitespaceRegex.test(password)) errorList['forbiddenWhitespace'] = true;

  if (!allowedCharacterRegex.test(password))
    errorList['forbiddenCharacter'] = true;

  return Object.keys(errorList).length ? errorList : null;
}
