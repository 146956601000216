import { LanguageControlGroup, Nullable } from '@xspot-app/common';

export interface BasketDto {
  actualPrice: number;
  originalPrice: number;
  items: BasketOfferDto[];
}

export interface BasketOfferDto {
  id: string;
  offerId: string;
  offerVariantId: string;
  actualPrice: number;
  originalPrice: number;
  offerName: LanguageControlGroup;
  offerVariantName: LanguageControlGroup;
  locationId: string;
  products: BasketOfferProductDto[];
  upsells: BasketOfferUpsellDto[];
  coverDesktopUri: string;
  coverMobileUri: string;
}

export interface BasketOfferProductDto {
  id: string;
  offerVariantProductId: string;
  actualPrice: number;
  originalPrice: number;
  productDisplayName: LanguageControlGroup;
  quantity: number;
  date: Nullable<string>;
  from: Nullable<string>;
  isMinimalAmount: boolean;
  numberOfMinutes: Nullable<number>;
}

export interface BasketOfferUpsellDto {
  id: string;
  upsellId: string;
  actualPrice: number;
  originalPrice: number;
  upsellName: LanguageControlGroup;
  quantity: number;
}
