import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone,
  PLATFORM_ID,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Tooltip } from 'primeng/tooltip';
import { PrimeNGConfig } from 'primeng/api';

@Directive({
  standalone: true,
  selector: '[xTooltip]',
})
export class XTooltipDirective extends Tooltip implements AfterViewInit {
  @Input('xTooltip') public override content:
    | string
    | TemplateRef<HTMLElement>
    | undefined;

  constructor(
    @Inject(PLATFORM_ID) platformId: object,
    el: ElementRef,
    zone: NgZone,
    config: PrimeNGConfig,
    private _renderer: Renderer2,
    viewContainer: ViewContainerRef
  ) {
    super(platformId, el, zone, config, _renderer, viewContainer);
  }

  public override ngAfterViewInit(): void {
    const ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    ) {
      this._renderer.setAttribute(this.el.nativeElement, 'tabindex', '0');
      this.setOption({ tooltipEvent: 'focus' });
    } else {
      this.setOption({ tooltipEvent: 'hover' });
    }
    super.ngAfterViewInit();
  }
}
