import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[changeEditorPlaceholder]',
})
export class ChangeEditorPlaceholderDirective {
  @HostListener('onInit') public onInit(): void {
    this.setQuillPlaceholder(this._placeholder);
  }
  @Input() public set changeEditorPlaceholder(value: string) {
    if (value !== this._placeholder) {
      this._placeholder = value;
      this.setQuillPlaceholder(value);
    }
  }

  private _placeholder: string = '';

  constructor(private elRef: ElementRef) {}

  private setQuillPlaceholder(placeholder: string): void {
    const items = this.elRef.nativeElement.getElementsByClassName('ql-editor');

    if (items.length !== 1) return;
    if (items.item(0).hasAttribute('data-placeholder'))
      items.item(0).removeAttribute('data-placeholder');

    items.item(0).setAttribute('data-placeholder', placeholder);
  }
}
