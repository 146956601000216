import { availableLanguages } from '../consts';
import { Translation } from '@ngneat/transloco';
import { InlineLoader } from '@ngneat/transloco/lib/types';

export function scopeLoaderFactory(
  loader: (lang: string) => Promise<Translation>
): InlineLoader {
  return availableLanguages.reduce(
    (acc, lang) => {
      acc[lang] = () => loader(lang);
      return acc;
    },
    {} as { [lang: string]: () => Promise<Translation> }
  );
}
