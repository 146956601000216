<ng-container *transloco="let t">
  <div class="form-control">
    <span class="p-float-label">
      <p-dropdown
        [options]="options"
        [formControl]="formControl"
        (onChange)="onVariantChange($event.value)"
        [emptyMessage]="t('form.noVariants')"
        [emptyFilterMessage]="t('form.noVariants')"
        styleClass="w-full">
      </p-dropdown>
      <label>{{ t('form.variant') }}</label>
    </span>
    <xspot-app-form-control-errors
      [control]="formControl"
      [prefix]="'form'"></xspot-app-form-control-errors>
  </div>
</ng-container>
