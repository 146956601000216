import {
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  FormGroup,
} from '@angular/forms';
import { DateTime } from 'luxon';

export function dateFromGreaterThanDateToValidator(
  firstControlName: string,
  secondControlName: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const group = control as FormGroup;

    const firstControlValue = group.controls[firstControlName].value;
    const secondControlValue = group.controls[secondControlName].value;

    if (!firstControlValue || !secondControlValue) {
      return null;
    }

    const firstDate = DateTime.fromJSDate(firstControlValue);
    const secondDate = DateTime.fromJSDate(secondControlValue);

    const errorKey = `${firstControlName}_${secondControlName}_valueMismatch`;

    return secondDate < firstDate ? { [errorKey]: true } : null;
  };
}
