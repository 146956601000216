<ng-container *ngIf="control?.touched">
  <small class="text-error" *ngFor="let error of errors | keyvalue">
    <ng-container *ngIf="prefix; else noPrefix">
      {{ 'errors.' + prefix + '.' + error.key | transloco }}
    </ng-container>
    <ng-template #noPrefix>
      {{ 'errors.' + error.key | transloco }}
    </ng-template>
  </small>
</ng-container>
