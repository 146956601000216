import { AbstractControl } from '@angular/forms';

export function NoWhitespaceValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  if (control.value && control.value.length != control.value.trim().length) {
    return { whitespace: true };
  }
  return null;
}
