import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDecimalFormat',
  standalone: true,
})
export class CustomDecimalFormatPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  public transform(value: number | string): string | null {
    if (value == null) return null;

    return this.decimalPipe.transform(value, '1.0-2', 'pl-PL');
  }
}
