import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { scopeLoaderFactory, StringUtil } from '@xspot-app/common';
import { provideTranslocoScope, TranslocoDirective } from '@ngneat/transloco';
import { debounceTime, distinctUntilChanged, map, skip, startWith } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'xspot-app-search-bar',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    ReactiveFormsModule,
    TranslocoDirective,
  ],
  templateUrl: './search-bar.component.html',
  providers: [
    provideTranslocoScope({
      scope: 'searchBar',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
  ],
})
export class SearchBarComponent implements OnInit {
  @Input() public minLength = 3;
  @Output() public search = new EventEmitter<string>();
  public searchControl = new FormControl<string>(StringUtil.empty);

  public ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(250),
        startWith(StringUtil.empty),
        map(value => {
          if (value === null || value.length === 0) {
            return StringUtil.empty;
          }
          const newValue = value.trim();
          return newValue.length >= this.minLength
            ? newValue
            : StringUtil.empty;
        }),
        distinctUntilChanged(),
        skip(1)
      )
      .subscribe(value => {
        this.search.emit(value);
      });
  }

  public clearSearch(): void {
    this.searchControl.reset();
  }
}
