import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormUtils<T extends string> {
  public addFormControlIfNotExists(
    controlNames: T[],
    controlDefinitions: Record<T, AbstractControl>,
    form: FormGroup
  ): void {
    controlNames.forEach(controlName => {
      if (form.contains(controlName)) return;

      const control = controlDefinitions[controlName];
      if (control) {
        control.reset();
        form.addControl(controlName, control);
      } else {
        throw new Error(`Control definition for ${controlName} is undefined`);
      }
    });
  }

  public removeControl(controlNames: T[], form: FormGroup): void {
    controlNames.forEach(controlName => {
      if (!form.contains(controlName)) return;

      form.removeControl(controlName);
    });
  }

  public createTranslationModelGroup(
    languages: string[],
    maxLength: number,
    additionalValidators: ValidatorFn[] = []
  ): FormGroup {
    const group: { [key: string]: FormControl } = {};

    languages.forEach(lang => {
      group[lang] = new FormControl('', [
        ...additionalValidators,
        Validators.maxLength(maxLength),
      ]);
    });

    return new FormGroup(group);
  }
}
