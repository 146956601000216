export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T {
  const keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);

  if (!keys.length) throw new Error('Enum value not found');

  return keys[0];
}
