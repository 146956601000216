import { GetVoucherDto } from '../../dtos';

export namespace Voucher {
  export class GetVoucher {
    public static readonly type = '[Voucher] Get Voucher';
    constructor(public payload: GetVoucherDto) {}
  }

  export class SetLoading {
    public static readonly type = '[Voucher] Set Loading';
    constructor(public isLoading: boolean) {}
  }

  export class ResetVouchers {
    public static readonly type = '[Voucher] Reset vouchers';
  }

  export class RemoveVoucher {
    public static readonly type = '[Voucher] Remove Voucher';
    constructor(public id: string) {}
  }
}
