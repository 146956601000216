import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import {
  OfferProductPriceKindEnum,
  OfferProductFormEnum,
  ProductKindEnum,
  SlotKindEnum,
} from '@xspot-app/common';
import { PriceSegment, VariantProductForm } from '../../domain';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'xspot-app-variant-product-form',
  standalone: true,
  imports: [
    CommonModule,
    DividerModule,
    DropdownModule,
    InputNumberModule,
    ReactiveFormsModule,
    TranslocoDirective,
  ],
  templateUrl: './variant-product-form.component.html',
})
export class VariantProductFormComponent implements OnInit {
  @Input() public productForm!: FormGroup<VariantProductForm>;

  public ProductKind = ProductKindEnum;

  public PriceKind = OfferProductPriceKindEnum;

  public ProductForm = OfferProductFormEnum;

  public productFormOptions = [
    { label: 'offers.variant.number', value: OfferProductFormEnum.Amount },
    { label: 'offers.variant.range', value: OfferProductFormEnum.Range },
  ];

  public priceKindOptions = [
    {
      label: 'offers.variant.singlePrice',
      value: OfferProductPriceKindEnum.Single,
    },
    {
      label: 'offers.variant.forSegments',
      value: OfferProductPriceKindEnum.Segments,
    },
  ];

  constructor(private fb: FormBuilder) {}

  public ngOnInit(): void {
    const controls = this.productForm.controls;
    this.setUpProductFromValidators();
    this.setUpPriceKindValidators();

    controls.productForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.setUpProductFromValidators();
      });

    controls.priceKind.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.setUpPriceKindValidators();
    });
  }

  private setUpProductFromValidators(): void {
    const controls = this.productForm.controls;

    if (
      this.productForm.controls.productForm.value ===
      OfferProductFormEnum.Amount
    ) {
      controls.amount.addValidators(Validators.required);
      controls.minAmount.removeValidators(Validators.required);
      controls.maxAmount.removeValidators(Validators.required);
      controls.startAmount.removeValidators(Validators.required);
      controls.minAmount.reset(null);
      controls.maxAmount.reset(null);
      controls.startAmount.reset(null);
    } else {
      controls.amount.removeValidators(Validators.required);
      controls.minAmount.addValidators(Validators.required);
      controls.maxAmount.addValidators(Validators.required);
      controls.startAmount.addValidators(Validators.required);
      controls.amount.reset(null);
    }

    this.productForm.updateValueAndValidity();
  }

  private setUpPriceKindValidators(): void {
    const controls = this.productForm.controls;

    if (
      this.productForm.controls.priceKind.value ===
      OfferProductPriceKindEnum.Single
    ) {
      controls.singlePrice.addValidators(Validators.required);

      controls.priceSegments.clear();
    } else if (controls.priceSegments.controls.length === 0) {
      controls.singlePrice.removeValidators(Validators.required);
      controls.singlePrice.reset(null);

      [
        this.fb.group<PriceSegment>({
          slotKind: this.fb.nonNullable.control(SlotKindEnum.Peak),
          price: this.fb.control(null, [
            Validators.required,
            Validators.min(0.01),
          ]),
        }),
        this.fb.group<PriceSegment>({
          slotKind: this.fb.nonNullable.control(SlotKindEnum.OffPeak),
          price: this.fb.control(null, [
            Validators.required,
            Validators.min(0.01),
          ]),
        }),
        this.fb.group<PriceSegment>({
          slotKind: this.fb.nonNullable.control(SlotKindEnum.WeekendPeak),
          price: this.fb.control(null, [
            Validators.required,
            Validators.min(0.01),
          ]),
        }),
        this.fb.group<PriceSegment>({
          slotKind: this.fb.nonNullable.control(SlotKindEnum.WeekendOffPeak),
          price: this.fb.control(null, [
            Validators.required,
            Validators.min(0.01),
          ]),
        }),
      ].forEach(priceSegment => {
        controls.priceSegments.push(priceSegment);
      });
    } else {
      controls.singlePrice.removeValidators(Validators.required);
      controls.singlePrice.reset(null);
    }

    this.productForm.updateValueAndValidity();
  }
}
