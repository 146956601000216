import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[validationTextColor]',
  standalone: true,
})
export class ValidationTextColorDirective implements OnChanges {
  @Input('validationTextColor') public errorCondition = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['errorCondition']) {
      this.updateErrorClass();
    }
  }
  private updateErrorClass(): void {
    if (this.errorCondition) {
      this.renderer.addClass(this.el.nativeElement, 'text-error');
      this.renderer.removeClass(this.el.nativeElement, 'text-success');
    } else {
      this.renderer.addClass(this.el.nativeElement, 'text-success');
      this.renderer.removeClass(this.el.nativeElement, 'text-error');
    }
  }
}
