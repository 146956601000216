import { Component, forwardRef } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { provideTranslocoScope, TranslocoDirective } from '@ngneat/transloco';
import {
  ErrorMessage,
  getErrorMessage,
  MaxFileSize_MB,
  Nullable,
  RecommendedAspectRatio,
  scopeLoaderFactory,
} from '@xspot-app/common';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ImageModel } from '../../models/image.model';
import { DragAndDropDirective } from '../../directives';
import { LibraryFilesDto } from '../../dtos/library-files.dto';
import { FilesLibraryUploadService } from '../../services';
import { FileUploadErrorCodes, FileUploadErrorMessages } from '../../consts';

@UntilDestroy()
@Component({
  selector: 'xspot-app-image-upload',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TranslocoDirective,
    ReactiveFormsModule,
    NgOptimizedImage,
    DragAndDropDirective,
  ],
  templateUrl: './image-upload.component.html',
  styleUrl: './image-upload.component.scss',
  providers: [
    provideTranslocoScope({
      scope: 'ui',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadComponent),
      multi: true,
    },
  ],
})
export class ImageUploadComponent implements ControlValueAccessor {
  public errorMessage: ErrorMessage | null = null;
  public val: Nullable<ImageModel> = null;
  public isLoading: boolean = false;
  public isDisabled: boolean = false;
  public fileOver: boolean = false;
  public MaxFileSize_MB = MaxFileSize_MB;
  public RecommendedAspectRatio = RecommendedAspectRatio;

  public onChange: (value: Nullable<ImageModel>) => void = () => {};
  public onTouched: (value: Nullable<ImageModel>) => void = () => {};

  constructor(private filesLibraryUploadService: FilesLibraryUploadService) {}

  public writeValue(image: Nullable<ImageModel>): void {
    this.val = image;
    this.onChange(this.val);
    this.onTouched(this.val);
  }
  public registerOnChange(fn: (value: Nullable<ImageModel>) => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: (value: Nullable<ImageModel>) => void): void {
    this.onTouched = fn;
  }
  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public upload(event: Event): void {
    const fileInput = event.target as HTMLInputElement;

    if (!fileInput.files || !fileInput.files[0]) return;

    const file = fileInput.files[0];
    this.sendFile(file);
  }

  public uploadFiles(files: FileList): void {
    if (files.length !== 1)
      throw new Error('Only one file can be uploaded at a time');

    const file = files[0];
    this.sendFile(file);
  }

  private sendFile(file: File): void {
    if (file.size / 1024 / 1024 > MaxFileSize_MB) {
      this.errorMessage = getErrorMessage(
        FileUploadErrorCodes.ValidationFailed,
        FileUploadErrorMessages,
        () => 'error'
      );
      return;
    }

    this.isLoading = true;
    this.errorMessage = null;

    this.filesLibraryUploadService
      .upload([file])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result: LibraryFilesDto) => {
          this.isLoading = false;
          this.writeValue(result[0]);
        },
        error: ({ error }) => {
          this.isLoading = false;
          this.writeValue(null);
          this.errorMessage = getErrorMessage(
            error.errorCode,
            FileUploadErrorMessages,
            () => 'error'
          );
        },
      });
  }
}
