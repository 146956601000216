import { Buffer } from 'buffer';

export class Base64Util {
  public static encode(value: object): string {
    return Buffer.from(JSON.stringify(value)).toString('base64');
  }

  public static decode<T>(value: string): T {
    return JSON.parse(Buffer.from(value, 'base64').toString('utf-8')) as T;
  }
}
