import { PagedList } from '@xspot-app/common';
import { OffersTreeOfferNodeDto } from '../dtos';
import { OfferModel, OrderedOfferModel } from '../domain';
import { OfferDetailsModel } from '../domain/offer-details.model';

export interface OffersStateModel {
  all: PagedList<OfferModel>;
  orderedList: OrderedOfferModel[];
  isVariantDialogVisible: boolean;
  details: {
    [id: string]: OfferDetailsModel;
  };
  offersTree: OffersTreeOfferNodeDto[];
}
