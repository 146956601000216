import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CalendarFileService {
  constructor(private http: HttpClient) {}

  public getCalendarFile(): Observable<HttpResponse<Blob>> {
    return this.http.get(`../assets/flyspot/Flyspot-cal.ics`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  //TODO: uncomment if BE ready
  // public getCalendarFile(orderId: string):  Observable<HttpResponse<Blob>> {
  //   return this.http.get(`${environment.apiUrl}/api/.../${orderId}`, {responseType: 'blob', observe: 'response'});
  // }
}
