import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { UserService } from '../services';
import { User } from './user.actions';
import { UserProfileDto } from '../dtos';
import {
  UserAgreementModel,
  UserProfileModel,
  UserStateModel,
} from '../domain';
import { produce } from 'immer';
import { GusCompanyDataModel } from '../domain/gus-company-data.model';
import { GusCompanyDataDto } from '../dtos/gus-company-data.dto';
import { StringUtil } from '@xspot-app/common';

const USER_STATE_TOKEN = new StateToken<UserStateModel>('user');

@State<UserStateModel>({
  name: USER_STATE_TOKEN,
  defaults: {
    user: null,
    profile: null,
    gusCompanyData: null,
  },
})
@Injectable()
export class UserState {
  constructor(private userService: UserService) {}

  @Selector()
  public static profile(state: UserStateModel): UserProfileModel | null {
    return state.profile;
  }

  @Selector()
  public static agreements(state: UserStateModel): UserAgreementModel[] | null {
    return state.profile?.agreements ?? [];
  }

  @Selector()
  public static gusData(state: UserStateModel): GusCompanyDataModel | null {
    return state.gusCompanyData;
  }

  @Action(User.Create)
  public create(
    ctx: StateContext<UserStateModel>,
    action: User.Create
  ): Observable<void> {
    return this.userService.create(action.payload);
  }

  @Action(User.CreateExternal)
  public createExternal(
    ctx: StateContext<UserStateModel>,
    action: User.CreateExternal
  ): Observable<void> {
    return this.userService.createExternalUser(action.payload);
  }

  @Action(User.FetchProfile)
  public fetchProfile({
    patchState,
  }: StateContext<UserStateModel>): Observable<void> {
    return this.userService.fetchProfile().pipe(
      map((payload: UserProfileDto) => {
        patchState({ profile: { ...payload } });
        return;
      })
    );
  }

  @Action(User.UpdateProfile)
  public updateProfile(
    ctx: StateContext<UserStateModel>,
    { payload }: User.UpdateProfile
  ): Observable<void> {
    return this.userService.updateProfile(payload).pipe(
      tap(() => {
        const state = produce(ctx.getState(), draft => {
          draft.profile = {
            ...draft.profile!,
            ...payload,
          };
        });

        ctx.setState(state);
      })
    );
  }

  @Action(User.UpdateAgreements)
  public updateAgreements(
    ctx: StateContext<UserStateModel>,
    action: User.UpdateAgreements
  ): Observable<void> {
    return this.userService.updateAgreements(action.payload).pipe(
      tap(() => {
        const state = produce(ctx.getState(), draft => {
          draft.profile!.agreements = {
            ...draft.profile!.agreements,
            ...action.payload,
          };
        });

        ctx.setState(state);
      })
    );
  }

  @Action(User.GetGusCompanyData, { cancelUncompleted: true })
  public getGusCompanyData(
    ctx: StateContext<UserStateModel>,
    action: User.GetGusCompanyData
  ): Observable<GusCompanyDataDto> {
    return this.userService.getGusCompanyData(action.taxNumber).pipe(
      tap((response: GusCompanyDataDto) => {
        const street = response.street
          ? `${response.street} ${response.streetNumber ?? StringUtil.empty}`
          : StringUtil.empty;

        const model: GusCompanyDataModel = {
          taxNumber: response.taxNumber,
          name: response.name,
          city: response.city ?? StringUtil.empty,
          postalCode: response.postalCode ?? StringUtil.empty,
          street: street.trim(),
          number: response.flatNumber ?? StringUtil.empty,
        };
        const state = produce(ctx.getState(), draft => {
          draft.gusCompanyData = model;
        });
        ctx.setState(state);
      })
    );
  }
}
