import { AbstractControl, ValidatorFn } from '@angular/forms';

export function forbiddenLengthValidator(length: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value && control.value.length === length) {
      return { forbiddenLength: true };
    }
    return null;
  };
}
