import { AbstractControl } from '@angular/forms';
import { formDateFormat, formTimeFormat } from '../consts';
import { DateTime } from 'luxon';
import { Nullable } from '../helpers';

export function DateTimeGreaterThanValidator(
  controlDate: Nullable<AbstractControl>,
  compareToDate: Nullable<AbstractControl>,
  compareToTime: Nullable<AbstractControl>
) {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const dateValue: string = controlDate?.value;
    const timeValue: string = control.value;
    const compareToDateValue: string = compareToDate?.value;
    const compareToTimeValue: string = compareToTime?.value;

    if (
      !dateValue ||
      !timeValue ||
      !compareToDateValue ||
      !compareToTimeValue
    ) {
      return null;
    }

    const parsedDate = DateTime.fromFormat(dateValue, formDateFormat);
    const parsedTime = DateTime.fromFormat(timeValue, formTimeFormat);
    const parsedCompareToDate = DateTime.fromFormat(
      compareToDateValue,
      formDateFormat
    );
    const parsedCompareToTime = DateTime.fromFormat(
      compareToTimeValue,
      formTimeFormat
    );

    if (
      !parsedDate.isValid ||
      !parsedTime.isValid ||
      !parsedCompareToDate.isValid ||
      !parsedCompareToTime.isValid
    ) {
      return null;
    }

    if (parsedDate < parsedCompareToDate) {
      return {
        dateTimeGreaterThan: true,
      };
    }
    if (parsedDate > parsedCompareToDate) {
      return null;
    }

    if (parsedTime <= parsedCompareToTime) {
      return {
        dateTimeGreaterThan: true,
      };
    }

    return null;
  };
}
