import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class MenuService {
  private showLeftMenu = new BehaviorSubject<boolean>(false);

  public currentMenuState: Observable<boolean> =
    this.showLeftMenu.asObservable();

  public toggleLeftMenu(): void {
    this.showLeftMenu.next(!this.showLeftMenu.value);
  }

  public hideMenu(): void {
    this.showLeftMenu.next(false);
  }
}
