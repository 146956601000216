import { Nullable } from '@xspot-app/common';
import { OfferModel } from '../../domain';
import { CustomerOfferModel } from '../../domain/customer-offer.model';
import {
  CalendarDaysDto,
  CalendarDaysSlotDto,
  UpsellDto,
  CustomerOffersListDto,
  SelectedProductsMinimalPriceDto,
} from '../../dtos';
import { CalendarTypeEnum } from '../../enums';

export interface OffersStateModel {
  all: CustomerOffersListDto[];
  selectedOffer: CustomerOfferModel | null;
  selectedOfferForm: OfferModel | null;
  calendarType: CalendarTypeEnum | null;
  offerCalendarDays: CalendarDaysDto | null;
  offerCalendarDaySlots: CalendarDaysSlotDto[] | null;
  upsells: UpsellDto[];
  minimalPrice: Nullable<SelectedProductsMinimalPriceDto>;
}
