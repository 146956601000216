import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomerLocationsListDto } from '../dtos';
import { environment } from '@xspot-app/common';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  constructor(private http: HttpClient) {}

  public getLocationsList(): Observable<CustomerLocationsListDto[]> {
    return this.http.get<CustomerLocationsListDto[]>(
      `${environment.apiUrl}/api/locations`
    );
  }
}
