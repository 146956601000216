<ng-container *transloco="let t">
  <div class="flex flex-column gap-4" [formGroup]="productForm">
    <div class="flex gap-4">
      <div>
        <label>{{ t('offers.variant.kind') }}: </label>
        <span class="text-primary">
          {{ t('offers.variant.' + productForm.value.kind) }}
        </span>
      </div>

      <div *ngIf="productForm.value.kind === ProductKind.Credit">
        <label>{{ t('offers.variant.creditKind') }}: </label>
        <span class="text-primary">
          {{ t('offers.variant.creditKinds.' + productForm.value.creditKind) }}
        </span>
      </div>

      <div *ngIf="productForm.value.kind === ProductKind.Reservation">
        <label>{{ t('offers.variant.ageGroup') }}: </label>
        <span class="text-primary">
          {{
            t('offers.variant.ageGroupKind.' + productForm.value.ageGroupKind)
          }}
        </span>
      </div>

      <div *ngIf="productForm.value.kind !== ProductKind.Supplement">
        <label>{{ t('offers.variant.minutes') }}: </label>
        <span class="text-primary">{{ productForm.value.productAmount }}</span>
      </div>

      <div *ngIf="productForm.value.kind === ProductKind.Credit">
        <label>{{ t('offers.variant.expireAt') }}: </label>
        <span class="text-primary">
          {{ productForm.value.expireAt | date: 'dd.MM.yyyy, HH:mm' }}
        </span>
      </div>
    </div>

    <div class="grid">
      <div class="form-control col-12 md:col-3">
        <span class="p-float-label">
          <p-dropdown
            styleClass="w-full"
            formControlName="productForm"
            [options]="productFormOptions"
            [placeholder]="t('offers.variant.productForm')">
            <ng-template let-item pTemplate="selectedItem">
              <span *ngIf="item">{{ t(item.label) }}</span>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <span>{{ t(item.label) }}</span>
            </ng-template>
          </p-dropdown>
          <label>{{ t('offers.variant.productForm') }}</label>
        </span>
      </div>

      <div
        *ngIf="
          productForm.value.productForm === ProductForm.Amount ||
          productForm.value.isMinimalAmount
        "
        class="form-control col-2">
        <span class="p-float-label">
          <p-inputNumber formControlName="amount" [min]="0"> </p-inputNumber>
          <label>{{ t('offers.variant.numberOfItemsInOffer') }}</label>
        </span>
        <ng-container
          *ngIf="
            productForm.controls['amount']?.touched &&
            productForm.controls['amount']?.errors as errors
          ">
          <small class="text-error" *ngIf="errors['required']">
            {{ t('form.isRequired') }}
          </small>
          <small class="text-error" *ngIf="errors['min']">
            {{ t('form.valueMustBeGreaterThan0') }}
          </small>
        </ng-container>
      </div>

      <ng-container *ngIf="productForm.value.productForm === ProductForm.Range">
        <div class="form-control col-6 md:col-3 xl:col-2">
          <span class="p-float-label">
            <p-inputNumber
              inputStyleClass="w-full"
              formControlName="minAmount"
              [min]="0">
            </p-inputNumber>
            <label>{{ t('offers.variant.minAmount') }}</label>
          </span>
          <ng-container
            *ngIf="
              productForm.controls['minAmount']?.touched &&
              productForm.controls['minAmount']?.errors as errors
            ">
            <small class="text-error" *ngIf="errors['required']">
              {{ t('form.isRequired') }}
            </small>
            <small class="text-error" *ngIf="errors['min']">
              {{ t('form.valueMustBeGreaterThan0') }}
            </small>
          </ng-container>
        </div>

        <div class="form-control col-6 md:col-3 xl:col-2">
          <span class="p-float-label">
            <p-inputNumber
              inputStyleClass="w-full"
              formControlName="maxAmount"
              [min]="0">
            </p-inputNumber>
            <label>{{ t('offers.variant.maxAmount') }}</label>
          </span>
          <ng-container
            *ngIf="
              productForm.controls['maxAmount']?.touched &&
              productForm.controls['maxAmount']?.errors as errors
            ">
            <small class="text-error" *ngIf="errors['required']">
              {{ t('form.isRequired') }}
            </small>
            <small class="text-error" *ngIf="errors['min']">
              {{ t('form.valueMustBeGreaterThan0') }}
            </small>
          </ng-container>
        </div>

        <div class="form-control col-6 md:col-3 xl:col-2">
          <span class="p-float-label">
            <p-inputNumber
              inputStyleClass="w-full"
              formControlName="startAmount"
              [min]="0">
            </p-inputNumber>
            <label>{{ t('offers.variant.startAmount') }}</label>
          </span>
          <ng-container
            *ngIf="
              productForm.controls['startAmount']?.touched &&
              productForm.controls['startAmount']?.errors as errors
            ">
            <small class="text-error" *ngIf="errors['required']">
              {{ t('form.isRequired') }}
            </small>
            <small class="text-error" *ngIf="errors['min']">
              {{ t('form.valueMustBeGreaterThan0') }}
            </small>
          </ng-container>
        </div>

        <small
          class="col-12 text-error"
          *ngIf="productForm.errors && productForm.errors['minGreaterThanMax']">
          {{ t('offers.variant.minGreaterThanMax') }}
        </small>
        <small
          class="col-12 text-error"
          *ngIf="productForm.errors && productForm.errors['outOfRange']">
          {{ t('offers.variant.outOfRange') }}
        </small>
      </ng-container>
    </div>

    <p-divider></p-divider>

    <div class="grid">
      <div
        *ngIf="productForm.value.kind === ProductKind.Reservation"
        class="form-control col-12">
        <span class="p-float-label">
          <p-dropdown
            styleClass="w-full"
            formControlName="priceKind"
            [options]="priceKindOptions"
            [placeholder]="t('offers.variant.priceKind')">
            <ng-template let-item pTemplate="selectedItem">
              <span *ngIf="item">{{ t(item.label) }}</span>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <span>{{ t(item.label) }}</span>
            </ng-template>
          </p-dropdown>
          <label>{{ t('offers.variant.priceKind') }}</label>
        </span>
      </div>

      <div
        *ngIf="productForm.value.priceKind === PriceKind.Single"
        class="form-control col-3">
        <span class="p-float-label">
          <p-inputNumber
            formControlName="singlePrice"
            [min]="0.01"
            mode="decimal"
            [minFractionDigits]="0"
            [maxFractionDigits]="2">
          </p-inputNumber>
          <label>{{ t('offers.variant.price') }}</label>
        </span>
        <ng-container
          *ngIf="
            productForm.controls['singlePrice']?.touched &&
            productForm.controls['singlePrice']?.errors as errors
          ">
          <small class="text-error" *ngIf="errors['required']">
            {{ t('form.isRequired') }}
          </small>
          <small class="text-error" *ngIf="errors['min']">
            {{ t('form.valueMustBeGreaterThan0') }}
          </small>
        </ng-container>
      </div>

      <ng-container *ngIf="productForm.value.priceKind === PriceKind.Segments">
        <div
          class="form-control col-6 md:col-3 xl:col-2"
          *ngFor="
            let segment of productForm.controls['priceSegments'].controls
          ">
          <span class="p-float-label">
            <p-inputNumber
              [formControl]="segment.controls['price']"
              [min]="0.01"
              mode="decimal"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              inputStyleClass="w-full">
            </p-inputNumber>
            <label>
              {{ t('offers.variant.priceSegments.' + segment.value.slotKind) }}
            </label>
          </span>
          <ng-container
            *ngIf="
              segment.controls['price']?.touched &&
              segment.controls['price']?.errors as errors
            ">
            <small class="text-error" *ngIf="errors['required']">
              {{ t('form.isRequired') }}
            </small>
            <small class="text-error" *ngIf="errors['min']">
              {{ t('form.valueMustBeGreaterThan0') }}
            </small>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <p-divider></p-divider>
  </div>
</ng-container>
