<div class="w-full h-full bg-white">
  <div class="h-full mx-auto max-w-[1920px] lg:flex simply-layout">
    <div class="lg:col-6 p-4 flex justify-content-center">
      <div class="w-25rem">
        <ng-content></ng-content>
      </div>
    </div>
    <div
      [ngStyle]="{ 'background-image': 'url(' + backgroundUrl + ')' }"
      class="hidden lg:col-6 p-4 lg:flex overflow-hidden bg-contain bg-no-repeat bg-center bg-"></div>
  </div>
</div>
