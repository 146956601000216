import { FormControl, FormGroup } from '@angular/forms';
import { PaymentMethodEnum } from '@xspot-app/common';

export const invoiceNameMaxLength = 500;
export const invoiceCityMaxLength = 100;
export const invoicePostalCodeMaxLength = 10;
export const invoiceStreetMaxLength = 100;
export const invoiceNumberMaxLength = 100;

export interface BasketSummaryFormModel {
  paymentMethod: FormControl<PaymentMethodEnum | null>;
  agreements: FormGroup<BasketSummaryFormModelAgreements>;
  invoice: FormGroup<BasketSummaryFormModelInvoice>;
}

export interface BasketSummaryFormModelAgreements {
  termsOfService: FormControl<boolean>;
  marketing: FormControl<boolean>;
  statement: FormControl<boolean>;
}

export interface BasketSummaryFormModelInvoice {
  taxNumber: FormControl<string>;
  name: FormControl<string>;
  city: FormControl<string>;
  postalCode: FormControl<string>;
  street: FormControl<string>;
  number: FormControl<string>;
}
