import { EmployeeRole } from '../enums';

export function jwtToRoles(accessToken: string): EmployeeRole[] {
  const jwtData = accessToken.split('.')[1];
  const decodedJwtJsonData = window.atob(jwtData);
  const decodedJwtData = JSON.parse(decodedJwtJsonData);
  const roles = Array.isArray(decodedJwtData.role)
    ? decodedJwtData.role
    : [decodedJwtData.role];

  return roles;
}
