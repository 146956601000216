<div
  *transloco="let t"
  class="p-3 bg-white border-bottom-1 border-100 gap-3 align-items-center flex">
  <p-button
    [rounded]="true"
    [text]="true"
    styleClass="h-2rem w-2rem p-0 flex align-items-center justify-content-center -ml-1 shadow-none lg:hidden"
    (click)="toggleMenu()">
    <span
      class="pi"
      [ngClass]="[(isOpen$ | async) ? 'pi-times' : 'pi-bars']"></span>
  </p-button>

  <div class="flex-grow-1"></div>

  <p-dropdown
    [options]="languages"
    [(ngModel)]="selectedLanguage"
    (onChange)="changeLanguage($event)"
    optionLabel="value"
    optionValue="value">
    <ng-template pTemplate="selectedItem">
      <span *ngIf="selectedLanguage" class="uppercase">{{
        selectedLanguage
      }}</span>
    </ng-template>
    <ng-template pTemplate="item" let-item>
      <span class="uppercase">{{ item.value }}</span>
    </ng-template>
  </p-dropdown>

  <p-button
    [rounded]="true"
    [text]="true"
    severity="secondary"
    styleClass="h-2rem w-2rem p-0 flex align-items-center justify-content-center text-600 -mr-1">
    <span class="pi pi-bell"></span>
  </p-button>

  <p-button
    (click)="menu.toggle($event)"
    [rounded]="true"
    size="small"
    [text]="true"
    severity="secondary"
    styleClass="h-2rem w-2rem p-0 flex align-items-center justify-content-center surface-500 text-200 text-xs line-height-3">
    JK
  </p-button>

  <xspot-app-drop-menu #menu [items]="menuItems"></xspot-app-drop-menu>
</div>
