import { OfferModel } from '../../domain';
import {
  CalendarDaysDto,
  CalendarDaysSlotDto,
  UpsellDto,
  CustomerOffersListDto,
} from '../../dtos';
import { CustomerOfferDto } from '../../dtos/customer-offer.dto';
import { CalendarTypeEnum } from '../../enums';

export interface OffersStateModel {
  all: CustomerOffersListDto[];
  selectedOffer: CustomerOfferDto | null;
  selectedOfferForm: OfferModel | null;
  calendarType: CalendarTypeEnum | null;
  offerCalendarDays: CalendarDaysDto | null;
  offerCalendarDaySlots: CalendarDaysSlotDto[] | null;
  upsells: UpsellDto[];
}
