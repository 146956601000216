import {
  ExternalProviders,
  FacebookLogInDto,
  GoogleLogInDto,
} from '@xspot-app/shared/auth';

export interface ExternalLogInModel {
  provider: ExternalProviders;
  data: FacebookLogInDto | GoogleLogInDto;
}
