import { BasketItemProductDto } from './basket-item-product.dto';
import { BasketItemUpsellDto } from './basket-item-upsell.dto';

export interface BasketItemDto {
  id?: string;
  offerId: string;
  offerVariantId: string;
  locationId: string;
  totalPrice: number;
  products: BasketItemProductDto[];
  upsells: BasketItemUpsellDto[];
}
