<div class="flex flex-column h-full">
  <div class="flex flex-column gap-4">
    <div *ngFor="let toolBar of toolBars" class="bg-white">
      <ng-content *ngTemplateOutlet="toolBar.templateRef"></ng-content>
    </div>
  </div>
  <div class="overflow-y-auto overflow-x-hidden md:p-4 p-2 scroll-wrapper">
    <div class="bg-white">
      <ng-content select="[pageContent]"></ng-content>
    </div>
  </div>
</div>
