import { AbstractControl } from '@angular/forms';
import { PhoneNumber } from '../models';

export function PhoneNumberValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const phoneNumber: PhoneNumber = control.value;

  // Check if prefix and number match the respective regex patterns
  const prefixRegex = /^\+\d{2,4}$/;
  const numberRegex = /^\d{4,15}$/;

  if (phoneNumber) {
    const prefixValid = prefixRegex.test(phoneNumber.prefix);
    const numberValid = numberRegex.test(phoneNumber.number);

    if (!prefixValid || !numberValid) {
      return { invalidPhoneNumber: true };
    }
  }

  return null;
}
