import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { displayDateTime } from '../consts';

@Pipe({
  name: 'localDate',
  standalone: true,
})
export class LocalDatePipe implements PipeTransform {
  public transform(value: string, format: string = displayDateTime): string {
    const utcValue = DateTime.fromISO(value, { zone: 'utc' });
    return utcValue.isValid ? utcValue.toLocal().toFormat(format) : value;
  }
}
