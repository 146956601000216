import { LanguageControlGroup, UiContext } from '@xspot-app/common';

export interface CustomerLocationsListDto {
  id: string;
  name: LanguageControlGroup;
  format: UiContext;
  address: string;
  latitude: number;
  longitude: number;
}
