import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Key } from 'ts-key-enum';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DropdownModule } from 'primeng/dropdown';
import { NgxMaskDirective } from 'ngx-mask';
import { OTP_LENGTH } from './../../consts/opt-input.const';
import { StringUtil } from '@xspot-app/common';

@UntilDestroy()
@Component({
  selector: 'xspot-app-otp-input',
  standalone: true,
  imports: [
    CommonModule,
    AutoCompleteModule,
    InputTextModule,
    ReactiveFormsModule,
    DropdownModule,
    NgxMaskDirective,
  ],
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
})
export class OtpInputComponent {
  @Output() public codeUpdated = new EventEmitter<string>();

  public otpForm = new FormArray(
    Array.from({ length: OTP_LENGTH }, () => new FormControl(StringUtil.empty))
  );

  @ViewChildren('otpInput') public otpInputElements!: QueryList<ElementRef>;

  public handleKey(event: KeyboardEvent, index: number): void {
    if (this.shouldNavigateBackwards(event, index)) {
      this.navigateBackwards(index);
    }
  }

  private shouldNavigateBackwards(
    event: KeyboardEvent,
    index: number
  ): boolean {
    return (
      event.key === Key.Backspace &&
      index > 0 &&
      this.otpForm.at(index).value === StringUtil.empty
    );
  }

  private navigateBackwards(index: number): void {
    const prevInput = this.otpInputElements.toArray()[index - 1]
      .nativeElement as HTMLInputElement;
    prevInput.focus();
  }

  private isCompleteOtp(): boolean {
    return this.otpForm.value.join(StringUtil.empty).length === OTP_LENGTH;
  }

  private emitOtpIfComplete(): void {
    if (this.isCompleteOtp()) {
      this.codeUpdated.emit(this.otpForm.value.join(StringUtil.empty));
    }
  }

  public handleInputChange(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    if (value.match(/^\d$/)) {
      this.updateOtpControl(value, index);
      this.focusNextInput(index);
    } else {
      this.updateOtpControl(StringUtil.empty, index);
    }
    this.emitOtpIfComplete();
  }

  private updateOtpControl(value: string, index: number): void {
    this.otpForm.at(index).setValue(value, { emitEvent: false });
  }

  private focusNextInput(index: number): void {
    if (index < OTP_LENGTH - 1) {
      const nextInput = this.otpInputElements.toArray()[index + 1]
        .nativeElement as HTMLInputElement;
      nextInput.focus();
    }
  }

  public handlePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text');
    if (pastedText && pastedText.match(/^\d{6}$/)) {
      const digits = pastedText.split(StringUtil.empty);
      digits.forEach((digit, i) => {
        this.updateOtpControl(digit, i);
      });
      this.otpInputElements.last.nativeElement.focus();
    }
    event.preventDefault();
    this.emitOtpIfComplete();
  }
}
