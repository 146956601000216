import {
  LanguageControlGroup,
  Nullable,
  OfferMediaKindEnum,
  OfferProductFormEnum,
  OfferProductPriceKindEnum,
  ProductKindEnum,
  SlotKindEnum,
  VolumePriceDiscountKindEnum,
} from '@xspot-app/common';
import { LocationsDto } from '@xspot-app/shared/locations';

export interface CustomerOfferModel {
  id: string;
  name: LanguageControlGroup;
  teaser: LanguageControlGroup;
  description: LanguageControlGroup;
  legalContent: LanguageControlGroup;
  locations: LocationsDto;
  media: CustomerOfferMediaModel[];
  legalFiles: CustomerOfferLegalFileModel[];
  variants: CustomerOfferVariantModel[];
  hasUpsells: boolean;
}

export interface CustomerOfferMediaModel {
  kind: OfferMediaKindEnum;
  uri: string;
  Ordinal: number;
}

export interface CustomerOfferLegalFileModel {
  uri: string;
  fileName: string;
  ordinal: number;
}

export interface CustomerOfferVariantModel {
  id: string;
  name: LanguageControlGroup;
  ordinal: number;
  entries: CustomerOfferVariantEntryModel[];
  products: CustomerOfferVariantProductModel[];
  information: LanguageControlGroup;
  volumePrices: CustomerOfferVariantVolumePriceModel[];
}

export interface CustomerOfferVariantEntryModel {
  header: LanguageControlGroup;
  description: LanguageControlGroup;
  ordinal: number;
}

export interface CustomerOfferVariantProductModel {
  id: string;
  isMinimalAmount: Nullable<boolean>;
  productKind: ProductKindEnum;
  displayName: LanguageControlGroup;
  productForm: OfferProductFormEnum;
  minAmount?: Nullable<number>;
  maxAmount?: Nullable<number>;
  startAmount?: Nullable<number>;
  amount?: Nullable<number>;
  ordinal: number;
  priceKind: OfferProductPriceKindEnum;
  singlePrice: Nullable<number>;
  segmentPrices: CustomerOfferVariantProductSegmentPriceModel[];
}

export interface CustomerOfferVariantProductSegmentPriceModel {
  price: number;
  slotKind: SlotKindEnum;
}

export interface CustomerOfferVariantVolumePriceModel {
  id: string;
  discountKind: VolumePriceDiscountKindEnum;
  items: CustomerOfferVariantProductVolumePriceItemModel[];
  products: CustomerOfferVariantProductVolumePriceProductModel[];
  selectedProductCount: number;
  isMaximumThresholdReached: boolean;
}

export interface CustomerOfferVariantProductVolumePriceItemModel {
  from: number;
  discountValue: number;
}

export interface CustomerOfferVariantProductVolumePriceProductModel {
  id: string;
  displayName: LanguageControlGroup;
}
