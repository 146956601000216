import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlatformDetectorService {
  public isIos(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }
}
