import { AbstractControl } from '@angular/forms';

export function TaxNumberValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const taxNumber = control.value;
  const taxNumberPattern = /^[0-9]{10}$/;
  let checksum = 0;
  const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];

  if (!taxNumber) {
    return null;
  }
  if (!taxNumberPattern.test(control.value)) {
    return { invalidTaxNumber: true };
  }

  const digits = taxNumber.split('').map(Number);

  for (let i = 0; i < 9; i++) {
    checksum += digits[i] * weights[i];
  }

  const calculatedChecksum = checksum % 11;

  if (calculatedChecksum !== digits[9]) {
    return { invalidTaxNumber: true };
  }

  return null;
}
