import { Language } from '@xspot-app/common';
import { UserAgreementDto } from './user-agreemen.dto';

export interface FetchUserProfileDto {
  email: string;
  name: string;
  surname: string;
  dateOfBirth: string | null;
  language: Language;
  emailConfirmed?: boolean;
  phoneNumberPrefix: string;
  phoneNumberWithoutPrefix: string;
  agreements: UserAgreementDto[] | null;
}
