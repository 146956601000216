import { LanguageControlGroup } from '@xspot-app/common';
import { PriceDto } from './price.dto';

export interface CustomerOffersListDto {
  id: string;
  coverMobileUri: string;
  coverDesktopUri: string;
  name: LanguageControlGroup;
  teaser: LanguageControlGroup;
  ordinal: number;
  originalPrice: PriceDto;
  locationIds: string[];
  categories: string[];
}
