<div class="p-input-icon-right flex h-full" *transloco="let t">
  <i *ngIf="!searchControl.value; else clearBtn" class="pi pi-search"></i>
  <ng-template #clearBtn>
    <i class="pi pi-times cursor-pointer" (click)="clearSearch()"></i>
  </ng-template>
  <input
    class="h-full w-full text-lg py-2 pr-0 pl-3"
    type="text"
    pInputText
    [formControl]="searchControl"
    placeholder="{{ t('searchBar.searchPlaceholder') }}" />
</div>
