import { LanguageControlGroup } from '@xspot-app/common';
import { BasketOfferProductDto } from './basket.dto';

export interface InvalidBasketDto {
  unavailable: InvalidBasketItemDto[];
  priceChanged: InvalidBasketItemDto[];
}

export interface InvalidBasketItemDto {
  basketItemId: string;
  offerId: string;
  offerName: LanguageControlGroup;
  offerVariantId: string;
  offerVariantName: LanguageControlGroup;
  coverMobileUri: string;
  coverDesktopUri: string;
  locationId: string;
  locationName?: LanguageControlGroup;
  originalPrice: number;
  actualPrice: number;
  products: InvalidBasketItemProductDto[];
  upsells: InvalidBasketItemUpsellDto[];
}

export interface InvalidBasketItemUpsellDto {
  basketItemUpsellId: string;
  upsellName: LanguageControlGroup;
  quantity: number;
  originalPrice: number;
  actualPrice: number;
}

export interface InvalidBasketItemProductDto extends BasketOfferProductDto {}
