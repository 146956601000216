<div class="otp-wrapper">
  <input
    *ngFor="let control of otpForm.controls; index as i"
    type="text"
    class="text-center"
    maxlength="1"
    pInputText
    [formControl]="control"
    placeholder="0"
    (input)="handleInputChange($event, i)"
    (paste)="handlePaste($event)"
    (keydown)="handleKey($event, i)"
    #otpInput />
</div>
