import { AfterViewInit, Directive, Input } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import Inputmask from 'inputmask';
import { StringUtil } from '@xspot-app/common';

@Directive({
  selector: '[calendarMask]',
  standalone: true,
})
export class CalendarMaskDirective implements AfterViewInit {
  @Input() public calendarMask: string = StringUtil.empty;
  constructor(private primeCalendar: Calendar) {}

  public ngAfterViewInit(): void {
    new Inputmask(this.calendarMask).mask(this.getHTMLInput());
  }

  private getHTMLInput(): HTMLInputElement {
    return this.primeCalendar.el.nativeElement.querySelector('input');
  }
}
