import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CalendarDaysDto,
  CalendarDaysSlotDto,
  CustomerOfferDto,
  CustomerOffersListDto,
  GetCalendarDaysSlotDto,
  GetOfferCalendarDto,
  UpsellDto,
} from '../dtos';
import { environment } from '@xspot-app/common';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  constructor(private http: HttpClient) {}

  public getOfferList(): Observable<CustomerOffersListDto[]> {
    return this.http.get<CustomerOffersListDto[]>(
      `${environment.apiUrl}/api/offers`
    );
  }

  public getOfferById(offerId: string): Observable<CustomerOfferDto> {
    return this.http.get<CustomerOfferDto>(
      `${environment.apiUrl}/api/offers/${offerId}`
    );
  }

  public getOfferCalendar(
    payload: GetOfferCalendarDto
  ): Observable<CalendarDaysDto> {
    return this.http.post<CalendarDaysDto>(
      `${environment.apiUrl}/api/calendars/days`,
      payload
    );
  }

  public getOfferCalendarSlots(
    payload: GetCalendarDaysSlotDto
  ): Observable<CalendarDaysSlotDto[]> {
    return this.http.post<CalendarDaysSlotDto[]>(
      `${environment.apiUrl}/api/calendars/days/slots`,
      payload
    );
  }

  public getOfferUpsells(offerId: string): Observable<UpsellDto[]> {
    return this.http.get<UpsellDto[]>(
      `${environment.apiUrl}/api/offers/${offerId}/upsells`
    );
  }
}
