import { Injectable } from '@angular/core';
import {
  StateToken,
  State,
  Selector,
  Action,
  StateContext,
  Store,
} from '@ngxs/store';
import { BasketsService, ShoppingPathStateService } from '../../services';
import { BasketsStateModel } from './baskets.state-model';
import {
  catchError,
  EMPTY,
  combineLatestWith,
  map,
  Observable,
  tap,
  switchMap,
} from 'rxjs';
import { Nullable, SessionIdService } from '@xspot-app/common';
import { BasketModel } from '../../domain';
import { Baskets } from './baskets.actions';
import { Locations, LocationsState } from '@xspot-app/customer/locations-api';
import { BasketModelMapper, mapLocations } from '../../mappers';

const BASKETS_STATE_TOKEN = new StateToken<BasketsStateModel>('baskets');

@State<BasketsStateModel>({
  name: BASKETS_STATE_TOKEN,
  defaults: {
    basket: null,
  },
})
@Injectable()
export class BasketsState {
  constructor(
    private basketsService: BasketsService,
    private sessionIdService: SessionIdService,
    private store: Store,
    private shoppingPathStateService: ShoppingPathStateService
  ) {}

  @Selector()
  public static basket(state: BasketsStateModel): Nullable<BasketModel> {
    return state.basket;
  }

  @Action(Baskets.GetItems)
  public getItems(
    ctx: StateContext<BasketsStateModel>
  ): Observable<BasketModel> {
    return this.basketsService.getItems().pipe(
      combineLatestWith(ctx.dispatch(Locations.GetAll)),
      map(([basketDto]) => {
        const basket = BasketModelMapper.map(basketDto);
        const locations = this.store.selectSnapshot(LocationsState.all);
        return { ...basket, offers: mapLocations(basket.offers, locations) };
      }),
      tap(basket => {
        ctx.patchState({
          basket: basket,
        });
      }),
      catchError(() => {
        ctx.patchState({
          basket: null,
        });
        this.shoppingPathStateService.resetCheckoutState();
        return EMPTY;
      })
    );
  }

  @Action(Baskets.UpdateCurrent)
  public updateBasket(
    ctx: StateContext<BasketsStateModel>,
    action: Baskets.UpdateCurrent
  ): void {
    ctx.patchState({
      basket: action.payload,
    });
  }

  @Action(Baskets.AddItem)
  public addItem(
    ctx: StateContext<BasketsStateModel>,
    { payload }: Baskets.AddItem
  ): Observable<void> {
    return this.basketsService.addItem(payload).pipe(
      tap(() => {
        ctx.dispatch(new Baskets.GetItems());
      })
    );
  }

  @Action(Baskets.UpdateItem)
  public updateItem(
    ctx: StateContext<BasketsStateModel>,
    { payload }: Baskets.UpdateItem
  ): Observable<void> {
    return this.basketsService.updateItem(payload).pipe(
      tap(() => {
        ctx.dispatch(new Baskets.GetItems());
      })
    );
  }

  @Action(Baskets.DeleteItem)
  public deleteItem(
    ctx: StateContext<BasketsStateModel>,
    { id }: Baskets.DeleteItem
  ): Observable<void> {
    return this.basketsService.deleteItem(id).pipe(
      switchMap(() => {
        if (ctx.getState().basket?.offers.length === 1) {
          ctx.patchState({
            basket: null,
          });
          this.sessionIdService.clearSessionId();

          return EMPTY;
        } else {
          return ctx.dispatch(new Baskets.GetItems());
        }
      })
    );
  }
}
