import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { OffersService } from '../../services';
import { OffersStateModel } from './offers.state-model';
import {
  CalendarDaysDto,
  CalendarDaysSlotDto,
  CustomerOfferDto,
  CustomerOffersListDto,
  OfferCalendarItemDto,
  UpsellDto,
} from '../../dtos';
import { Offers } from './offers.actions';
import { Observable, tap } from 'rxjs';
import { Nullable } from '@xspot-app/common';
import { produce } from 'immer';
import { CalendarTypeEnum } from '../../enums';
import { OfferModel } from '../../domain';

const OFFERS_STATE_TOKEN = new StateToken<OffersStateModel>('offers');

@State<OffersStateModel>({
  name: OFFERS_STATE_TOKEN,
  defaults: {
    all: [],
    selectedOffer: null,
    selectedOfferForm: null,
    calendarType: null,
    offerCalendarDays: null,
    offerCalendarDaySlots: null,
    upsells: [],
  },
})
@Injectable()
export class OffersState {
  constructor(private offersService: OffersService) {}

  @Selector()
  public static all(state: OffersStateModel): CustomerOffersListDto[] {
    return state.all;
  }

  @Selector()
  public static getSelectedOffer(
    state: OffersStateModel
  ): Nullable<CustomerOfferDto> {
    return state.selectedOffer;
  }

  @Selector()
  public static getSelectedOfferForm(
    state: OffersStateModel
  ): Nullable<OfferModel> {
    return state.selectedOfferForm;
  }

  @Selector()
  public static getCalendarType(
    state: OffersStateModel
  ): Nullable<CalendarTypeEnum> {
    return state.calendarType;
  }

  @Selector()
  public static getOfferCalendar(
    state: OffersStateModel
  ): OfferCalendarItemDto[] {
    return state.offerCalendarDays?.days ?? [];
  }

  @Selector()
  public static getOfferCalendarMinutesAmount(state: OffersStateModel): number {
    return state.offerCalendarDays?.minutesAmount ?? 0;
  }

  @Selector()
  public static getOfferCalendarSlots(
    state: OffersStateModel
  ): CalendarDaysSlotDto[] {
    return state.offerCalendarDaySlots ?? [];
  }

  @Selector()
  public static getOfferUpsells(state: OffersStateModel): UpsellDto[] {
    return state.upsells;
  }

  @Action(Offers.SetCalendarType)
  public setCalendarType(
    ctx: StateContext<OffersStateModel>,
    action: Offers.SetCalendarType
  ): void {
    ctx.patchState({ calendarType: action.calendarType });
  }

  @Action(Offers.GetAll)
  public getAllOffers(
    ctx: StateContext<OffersStateModel>
  ): Observable<CustomerOffersListDto[]> {
    return this.offersService.getOfferList().pipe(
      tap(data => {
        ctx.patchState({
          all: data,
        });
      })
    );
  }

  @Action(Offers.GetOfferById)
  public getOfferById(
    ctx: StateContext<OffersStateModel>,
    action: Offers.GetOfferById
  ): Observable<CustomerOfferDto> {
    return this.offersService.getOfferById(action.offerId).pipe(
      tap(data => {
        ctx.patchState({
          selectedOffer: data,
        });
      })
    );
  }

  @Action(Offers.GetOfferCalendar, {
    cancelUncompleted: true,
  })
  public getOfferCalendar(
    ctx: StateContext<OffersStateModel>,
    { dto }: Offers.GetOfferCalendar
  ): Observable<CalendarDaysDto> {
    return this.offersService.getOfferCalendar(dto).pipe(
      tap(response => {
        const state = produce(ctx.getState(), draft => {
          draft.offerCalendarDays = response;
        });

        ctx.setState(state);
      })
    );
  }

  @Action(Offers.GetOfferCalendarSlots, {
    cancelUncompleted: true,
  })
  public getOfferCalendarSlots(
    ctx: StateContext<OffersStateModel>,
    { dto }: Offers.GetOfferCalendarSlots
  ): Observable<CalendarDaysSlotDto[]> {
    return this.offersService.getOfferCalendarSlots(dto).pipe(
      tap(response => {
        const state = produce(ctx.getState(), draft => {
          draft.offerCalendarDaySlots = response;
        });

        ctx.setState(state);
      })
    );
  }

  @Action(Offers.GetOfferUpsells)
  public getOfferUpsells(
    ctx: StateContext<OffersStateModel>,
    action: Offers.GetOfferUpsells
  ): Observable<UpsellDto[]> {
    return this.offersService.getOfferUpsells(action.offerId).pipe(
      tap(data => {
        ctx.patchState({
          upsells: data,
        });
      })
    );
  }

  @Action(Offers.ResetSelectedOffer)
  public resetSelectedOffer(ctx: StateContext<OffersStateModel>): void {
    ctx.patchState({
      selectedOffer: null,
    });
  }

  @Action(Offers.SetSelectedOfferForm)
  public setOfferForm(
    ctx: StateContext<OffersStateModel>,
    action: Offers.SetSelectedOfferForm
  ): Observable<void> {
    let calendarAction: Observable<void>;
    if (
      action.formValues?.variant?.products.some(x => x.isMinimalAmount === true)
    ) {
      calendarAction = ctx.dispatch(
        new Offers.SetCalendarType(CalendarTypeEnum.FlyspotPro)
      );
    } else {
      calendarAction = ctx.dispatch(
        new Offers.SetCalendarType(CalendarTypeEnum.Flyspot)
      );
    }
    return calendarAction!.pipe(
      tap(() =>
        ctx.patchState({
          selectedOfferForm: action.formValues,
        })
      )
    );
  }
}
