<ng-container *transloco="let t">
  <p-sidebar
    [(visible)]="isOpen"
    (onHide)="hideMenu()"
    [showCloseIcon]="false"
    class="mobile min-w-20rem max-w-20rem">
    <ng-template pTemplate="header">
      <img class="cursor-pointer" [src]="logoUrl" alt="logo" routerLink="/" />
      <ng-container [ngTemplateOutlet]="switchContextOverlay"> </ng-container>
    </ng-template>

    <div>
      <ng-container [ngTemplateOutlet]="menuItemTemplate"></ng-container>
    </div>
  </p-sidebar>

  <div class="desktop h-full border-x-1 border-100 p-3 bg-white w-20rem">
    <div class="flex align-items-center mb-4">
      <img class="cursor-pointer" [src]="logoUrl" alt="logo" routerLink="/" />
      <ng-container [ngTemplateOutlet]="switchContextOverlay"> </ng-container>
    </div>

    <p-divider></p-divider>

    <ng-container [ngTemplateOutlet]="menuItemTemplate"></ng-container>
  </div>

  <ng-template #menuItemTemplate>
    <ul class="menu__list">
      <ng-container *ngFor="let item of menuItems$ | async">
        <ng-container
          *ngIf="item.action"
          [ngTemplateOutlet]="actionItem"
          [ngTemplateOutletContext]="{ item }"></ng-container>
        <ng-container
          *ngIf="item.url"
          [ngTemplateOutlet]="urlItem"
          [ngTemplateOutletContext]="{ item }">
        </ng-container>
        <ng-container
          *ngIf="item.items"
          [ngTemplateOutlet]="parentItem"
          [ngTemplateOutletContext]="{ item }">
        </ng-container>
      </ng-container>
    </ul>
  </ng-template>
  <ng-template #actionItem let-item="item">
    <li *ngIf="item.visible" class="menu__item" (click)="runItemAction(item)">
      <i class="mr-2 {{ item.icon }}" *ngIf="item.icon"></i>
      <span>{{
        item.notTranslate ? item.label : t('menu.' + item.label)
      }}</span>
    </li>
  </ng-template>

  <ng-template #urlItem let-item="item">
    <li
      *ngIf="item.visible"
      class="menu__item"
      [routerLink]="item.url"
      (click)="hideMenu()"
      routerLinkActive="menu__item--active">
      <i *ngIf="item.icon" class="mr-2 {{ item.icon }}"></i>
      <span>{{
        item.notTranslate ? item.label : t('menu.' + item.label)
      }}</span>
    </li></ng-template
  >

  <ng-template #parentItem let-item="item">
    <li
      *ngIf="item.visible"
      pRipple
      pStyleClass="@next"
      enterFromClass="hidden"
      enterActiveClass="slidedown"
      leaveToClass="hidden"
      leaveActiveClass="slideup"
      class="menu__item p-ripple">
      <i *ngIf="item.icon" class="mr-2 {{ item.icon }}"></i>
      <span>{{
        item.notTranslate ? item.label : t('menu.' + item.label)
      }}</span>
      <i class="pi pi-chevron-down ml-auto"></i>
    </li>

    <ul
      class="menu__list ml-2 overflow-y-hidden"
      [ngClass]="{ hidden: item.label !== (activeParentName$ | async) }">
      <ng-container *ngFor="let child of item.items">
        <ng-container
          *ngIf="child.action"
          [ngTemplateOutlet]="actionItem"
          [ngTemplateOutletContext]="{ item: child }"></ng-container>
        <ng-container
          *ngIf="child.url"
          [ngTemplateOutlet]="urlItem"
          [ngTemplateOutletContext]="{ item: child }">
        </ng-container>
      </ng-container>
    </ul>
  </ng-template>

  <ng-template #switchContextOverlay>
    <ng-container *ngIf="allowSwitchContext">
      <p-button
        icon="pi pi-chevron-down"
        [rounded]="true"
        [text]="true"
        severity="secondary"
        (click)="op.toggle($event)"></p-button>

      <p-overlayPanel #op>
        <div class="flex flex-column gap-2">
          <p-button
            [text]="true"
            severity="secondary"
            (click)="changeContext(UiContext.Flyspot)">
            <img
              class="cursor-pointer"
              src="assets/flyspot/logo.png"
              alt="flyspot logo" />
          </p-button>

          <p-button
            [text]="true"
            severity="secondary"
            (click)="changeContext(UiContext.Deepspot)">
            <img
              class="cursor-pointer"
              src="assets/deepspot/logo.png"
              alt="deepspot logo" />
          </p-button>
        </div>
      </p-overlayPanel>
    </ng-container>
  </ng-template>
</ng-container>
