import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { Button } from 'primeng/button';
import { TranslocoDirective } from '@ngneat/transloco';
import { EXTERNAL_URL } from '@xspot-app/common';

@Component({
  selector: 'xspot-app-voucher-banner',
  standalone: true,
  imports: [CommonModule, RouterLink, Button, TranslocoDirective],
  templateUrl: './voucher-banner.component.html',
  styleUrl: './voucher-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoucherBannerComponent {
  constructor(private router: Router) {}
  public toRedeemVoucher(): void {
    this.router.navigate(['/voucher']);
  }

  public toShopper(): void {
    window.location.href = EXTERNAL_URL.flyspotShoper;
  }
}
