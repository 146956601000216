import { Nullable } from '@xspot-app/common';
import { GetCalendarDaysSlotDto, GetOfferCalendarDto } from '../../dtos';
import { CalendarTypeEnum } from '../../enums';
import { OfferModel } from '../../domain';

export namespace Offers {
  export class GetAll {
    public static readonly type = '[Offers] GetAll';
  }

  export class GetOfferById {
    public static readonly type = '[Offers] GetOfferById';
    constructor(public offerId: string) {}
  }

  export class SetCalendarType {
    public static readonly type = '[Offers] Set Calendar Type';
    constructor(public calendarType: Nullable<CalendarTypeEnum>) {}
  }

  export class GetOfferCalendar {
    public static readonly type = '[Offers] Get Offer Calendar';
    constructor(public dto: GetOfferCalendarDto) {}
  }

  export class GetOfferCalendarSlots {
    public static readonly type = '[Offers] Get Offer Calendar Slots';
    constructor(public dto: GetCalendarDaysSlotDto) {}
  }

  export class GetOfferUpsells {
    public static readonly type = '[Offers] Get Offer Upsells';
    constructor(public offerId: string) {}
  }

  export class ResetSelectedOffer {
    public static readonly type = '[Offers] Reset Selected Offer';
  }

  export class SetSelectedOfferForm {
    public static readonly type = '[Offers] Set Offer Form';
    constructor(public formValues: Nullable<OfferModel>) {}
  }
}
