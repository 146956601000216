import { Time } from '@angular/common';

export const generateTimeArray = (enableHalfHours: boolean = false): Time[] => {
  const timeArray: Time[] = [];
  const increment = enableHalfHours ? 30 : 60;

  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes += increment) {
      timeArray.push({ hours, minutes });
    }
  }

  return timeArray;
};
