import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { OffersService, ShoppingPathStateService } from '../../services';
import { OffersStateModel } from './offers.state-model';
import {
  CalendarDaysDto,
  CalendarDaysSlotDto,
  CustomerOffersListDto,
  OfferCalendarItemDto,
  SelectedProductsMinimalPriceDto,
  UpsellDto,
} from '../../dtos';
import { Offers } from './offers.actions';
import { map, Observable, tap } from 'rxjs';
import { Nullable } from '@xspot-app/common';
import { produce } from 'immer';
import { CalendarTypeEnum } from '../../enums';
import { OfferModel } from '../../domain';
import { CustomerOfferModel } from '../../domain/customer-offer.model';
import { CustomerOfferModelMapper } from '../../mappers/customer-offer-model.mapper';

const OFFERS_STATE_TOKEN = new StateToken<OffersStateModel>('offers');

@State<OffersStateModel>({
  name: OFFERS_STATE_TOKEN,
  defaults: {
    all: [],
    selectedOffer: null,
    selectedOfferForm: null,
    calendarType: null,
    offerCalendarDays: null,
    offerCalendarDaySlots: null,
    upsells: [],
    minimalPrice: null,
  },
})
@Injectable()
export class OffersState {
  constructor(
    private offersService: OffersService,
    private shoppingPathStateService: ShoppingPathStateService
  ) {}

  @Selector()
  public static all(state: OffersStateModel): CustomerOffersListDto[] {
    return state.all;
  }

  @Selector()
  public static getSelectedOffer(
    state: OffersStateModel
  ): Nullable<CustomerOfferModel> {
    return state.selectedOffer;
  }

  @Selector()
  public static getSelectedOfferForm(
    state: OffersStateModel
  ): Nullable<OfferModel> {
    return state.selectedOfferForm;
  }

  @Selector()
  public static getCalendarType(
    state: OffersStateModel
  ): Nullable<CalendarTypeEnum> {
    return state.calendarType;
  }

  @Selector()
  public static getOfferCalendar(
    state: OffersStateModel
  ): OfferCalendarItemDto[] {
    return state.offerCalendarDays?.days ?? [];
  }

  @Selector()
  public static getMinimalPrice(
    state: OffersStateModel
  ): Nullable<SelectedProductsMinimalPriceDto> {
    return state.minimalPrice;
  }

  @Selector()
  public static getOfferCalendarMinutesAmount(state: OffersStateModel): number {
    return state.offerCalendarDays?.minutesAmount ?? 0;
  }

  @Selector()
  public static getOfferCalendarSlots(
    state: OffersStateModel
  ): CalendarDaysSlotDto[] {
    return state.offerCalendarDaySlots ?? [];
  }

  @Selector()
  public static getOfferUpsells(state: OffersStateModel): UpsellDto[] {
    return state.upsells;
  }

  @Action(Offers.SetCalendarType)
  public setCalendarType(
    ctx: StateContext<OffersStateModel>,
    action: Offers.SetCalendarType
  ): void {
    ctx.patchState({ calendarType: action.calendarType });
  }

  @Action(Offers.GetAll)
  public getAllOffers(
    ctx: StateContext<OffersStateModel>
  ): Observable<CustomerOffersListDto[]> {
    return this.offersService.getOfferList().pipe(
      tap(data => {
        ctx.patchState({
          all: data,
        });
      })
    );
  }

  @Action(Offers.ResetMinimalPrice)
  public resetMinimalPrice(ctx: StateContext<OffersStateModel>): void {
    ctx.patchState({ minimalPrice: null });
  }

  @Action(Offers.GetMinimalPrice, { cancelUncompleted: true })
  public getMinimalPrice(
    ctx: StateContext<OffersStateModel>,
    action: Offers.GetMinimalPrice
  ): Observable<SelectedProductsMinimalPriceDto> {
    return this.offersService
      .getMinimalPrice(action.offerId, action.payload)
      .pipe(
        tap((result: SelectedProductsMinimalPriceDto) => {
          ctx.patchState({
            minimalPrice: result,
          });
        })
      );
  }

  @Action(Offers.GetOfferById)
  public getOfferById(
    ctx: StateContext<OffersStateModel>,
    action: Offers.GetOfferById
  ): Observable<CustomerOfferModel> {
    return this.offersService.getOfferById(action.offerId).pipe(
      map(dto => {
        return CustomerOfferModelMapper.map(dto);
      }),
      tap(model => {
        ctx.patchState({
          selectedOffer: model,
        });
      })
    );
  }

  @Action(Offers.GetOfferCalendar, {
    cancelUncompleted: true,
  })
  public getOfferCalendar(
    ctx: StateContext<OffersStateModel>,
    { dto }: Offers.GetOfferCalendar
  ): Observable<CalendarDaysDto> {
    return this.offersService.getOfferCalendar(dto).pipe(
      tap(response => {
        const state = produce(ctx.getState(), draft => {
          draft.offerCalendarDays = response;
        });

        ctx.setState(state);
      })
    );
  }

  @Action(Offers.GetOfferCalendarSlots, {
    cancelUncompleted: true,
  })
  public getOfferCalendarSlots(
    ctx: StateContext<OffersStateModel>,
    { dto }: Offers.GetOfferCalendarSlots
  ): Observable<CalendarDaysSlotDto[]> {
    return this.offersService.getOfferCalendarSlots(dto).pipe(
      tap(response => {
        const state = produce(ctx.getState(), draft => {
          draft.offerCalendarDaySlots = response;
        });

        ctx.setState(state);
      })
    );
  }

  @Action(Offers.GetOfferUpsells)
  public getOfferUpsells(
    ctx: StateContext<OffersStateModel>,
    action: Offers.GetOfferUpsells
  ): Observable<UpsellDto[]> {
    return this.offersService.getOfferUpsells(action.offerId).pipe(
      tap(data => {
        ctx.patchState({
          upsells: data,
        });
      })
    );
  }

  @Action(Offers.ResetSelectedOffer)
  public resetSelectedOffer(ctx: StateContext<OffersStateModel>): void {
    ctx.patchState({
      selectedOffer: null,
    });
  }

  @Action(Offers.SetSelectedOfferForm)
  public setOfferForm(
    ctx: StateContext<OffersStateModel>,
    action: Offers.SetSelectedOfferForm
  ): Observable<void> {
    if (action.formValues) {
      this.shoppingPathStateService.setOfferForm(action.formValues);
    } else {
      this.shoppingPathStateService.resetOfferForm();
    }

    let calendarAction: Observable<void>;
    if (
      action.formValues?.variant?.products.some(x => x.isMinimalAmount === true)
    ) {
      calendarAction = ctx.dispatch(
        new Offers.SetCalendarType(CalendarTypeEnum.FlyspotPro)
      );
    } else {
      calendarAction = ctx.dispatch(
        new Offers.SetCalendarType(CalendarTypeEnum.Flyspot)
      );
    }
    return calendarAction!.pipe(
      tap(() =>
        ctx.patchState({
          selectedOfferForm: action.formValues,
        })
      )
    );
  }

  @Action(Offers.RestoreSelectedOfferForm)
  public restoreOfferForm(ctx: StateContext<OffersStateModel>): void {
    const offerModel = this.shoppingPathStateService.getOfferModel();

    if (offerModel) {
      ctx.patchState({
        selectedOfferForm: offerModel,
      });
    }
  }
}
