export const formDateFormat = 'dd.MM.yyyy';
export const calendarDateFormat = 'dd.mm.yy';
export const calendarMask = '99.99.9999';
export const inputDateMask = '00.00.0000';
export const fbDateFormat = 'MM/dd/yyyy';
export const formTimeFormat = 'HH:mm';
export const formTimeFormatWithSeconds = 'HH:mm:ss';
export const formDateTimeFormat = `${formDateFormat} ${formTimeFormat}`;

export const routingParamDateFormat = 'yyyy-MM-dd';

export const displayDate = 'dd.MM.yyyy';
export const displayDateTime = 'dd.MM.yyyy, HH:mm';
