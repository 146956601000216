import { HttpResponse } from '@angular/common/http';

export const MaxFileSize_MB = 2;
export const RecommendedAspectRatio = '16:9';

export function getFileName(response: HttpResponse<unknown>): string {
  const contentDisposition = response.headers.get('content-disposition');

  if (!contentDisposition)
    throw new Error('Content-Disposition header not found');

  return contentDisposition
    .split(';')[1]
    .split('filename')[1]
    .split('=')[1]
    .trim();
}

export function downloadFile(filename: string, file: Blob): void {
  const blobUrl = window.URL.createObjectURL(file);
  const link = document.createElement('a');

  link.href = blobUrl;
  link.download = filename;
  link.click();

  window.URL.revokeObjectURL(blobUrl);
}
