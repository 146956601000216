import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AUTH_INTERCEPTOR_CONFIG } from '../consts';
import { TokenResponse } from '../models';
import { StringUtil } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly tokenKey = 'access_token';
  private readonly refreshKey = 'refresh_token';
  private accessTokenSubject = new BehaviorSubject<string>(StringUtil.empty);
  public accessToken$ = this.accessTokenSubject.asObservable();
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  constructor(
    private router: Router,
    private http: HttpClient,
    @Inject(AUTH_INTERCEPTOR_CONFIG)
    private config: { apiEndpointForRefreshToken: string }
  ) {
    this.accessToken$.subscribe(accessToken => {
      this.isAuthenticatedSubject.next(!!accessToken);
    });
  }

  public isAuthenticated(): Observable<boolean | UrlTree> {
    const accessToken = this.getAccessToken();
    const refreshToken = this.getRefreshToken();

    if (!accessToken && !refreshToken) {
      return of(this.router.createUrlTree(['/auth']));
    }

    if (accessToken) {
      return of(true);
    }

    return this.refreshToken().pipe(
      switchMap(() => {
        return of(true);
      }),
      catchError(() => {
        this.clearTokens();
        return of(this.router.createUrlTree(['/auth']));
      })
    );
  }

  public isAnonymous(): Observable<boolean | UrlTree> {
    const accessToken = this.getAccessToken();
    const refreshToken = this.getRefreshToken();

    if (accessToken || refreshToken) {
      return of(this.router.createUrlTree(['/']));
    }

    return of(true);
  }

  public isLoggedIn(): boolean {
    return !!this.getAccessToken();
  }

  public setToken(accessToken: string, refreshToken: string): void {
    localStorage.setItem(this.tokenKey, accessToken);

    if (refreshToken) {
      localStorage.setItem(this.refreshKey, refreshToken);
    }

    this.accessTokenSubject.next(accessToken);
  }

  public getAccessToken(): string | null {
    const token = localStorage.getItem(this.tokenKey);
    this.accessTokenSubject.next(token ?? StringUtil.empty);
    return token;
  }

  public getRefreshToken(): string | null {
    return localStorage.getItem(this.refreshKey);
  }

  public clearTokens(): void {
    this.accessTokenSubject.next(StringUtil.empty);
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.refreshKey);
  }

  private refreshToken(): Observable<TokenResponse> {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      return throwError(() => new Error('NO_REFRESH_TOKEN'));
    }

    return this.http
      .post<TokenResponse>(this.config.apiEndpointForRefreshToken, {
        refreshToken,
      })
      .pipe(
        switchMap(response => {
          this.setToken(response.accessToken, response.refreshToken);
          return of(response);
        }),
        catchError(error => {
          return throwError(() => new Error(error));
        })
      );
  }
}
