import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { provideTranslocoScope, TranslocoDirective } from '@ngneat/transloco';
import {
  EmailValidator,
  highlightFormErrors,
  scopeLoaderFactory,
  StringUtil,
} from '@xspot-app/common';
import { LoginFormData } from '../../models/login-form-data';
import { PasswordModule } from 'primeng/password';

@Component({
  selector: 'xspot-app-login-form',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    RouterLink,
    TranslocoDirective,
  ],
  templateUrl: './login-form.component.html',
  providers: [
    provideTranslocoScope({
      scope: 'loginForm',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
  ],
})
export class LoginFormComponent {
  @Output() public login = new EventEmitter<LoginFormData>();
  @Input() public loading = false;
  @Output() public loadingChange = new EventEmitter<boolean>();

  public loginForm: FormGroup<{
    email: FormControl<string>;
    password: FormControl<string>;
  }>;

  public get emailControl(): AbstractControl {
    return this.loginForm.controls.email;
  }
  public get passwordControl(): AbstractControl {
    return this.loginForm.controls.password;
  }

  constructor(private fb: NonNullableFormBuilder) {
    this.loginForm = this.fb.group({
      email: this.fb.control(StringUtil.empty, [
        Validators.required,
        Validators.email,
        EmailValidator,
      ]),
      password: this.fb.control(StringUtil.empty, [Validators.required]),
    });
  }

  public submitForm(): void {
    if (this.loginForm.invalid) {
      highlightFormErrors(this.loginForm);
      return;
    }

    const formValues = this.loginForm.value;
    this.login.emit(formValues as LoginFormData);
  }
}
