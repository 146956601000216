import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  forwardRef,
  OnInit,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControlErrorsComponent } from './../';
import { DropdownModule } from 'primeng/dropdown';
import { SelectOption } from '@xspot-app/common';
import { Store } from '@ngxs/store';
import { Nullable } from 'primeng/ts-helpers';
import { OverlayListenerOptions, OverlayOptions } from 'primeng/api';
// TODO: fix it
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Offers, UpdateOfferVariantDto } from '@xspot-app/back-office/offers';
import { SelectedVariantDto } from '../../dtos';

@UntilDestroy()
@Component({
  selector: 'xspot-app-offer-variants-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    FormControlErrorsComponent,
    TranslocoDirective,
  ],
  templateUrl: './offer-variants-dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OfferVariantsDropdownComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OfferVariantsDropdownComponent),
      multi: true,
    },
  ],
})
export class OfferVariantsDropdownComponent
  implements ControlValueAccessor, OnChanges, Validator, OnInit
{
  @Input() public formControl!: FormControl<Nullable<string>>;
  @Input() public offerId!: Nullable<string>;
  @Input() public isEditMode: boolean = false;
  @Input() public initialOption!: SelectOption;
  @Output() public variantsSelected = new EventEmitter<SelectedVariantDto>();

  public onChange: (value: Nullable<string>) => void = () => {};
  public onTouched: (value: Nullable<string>) => void = () => {};

  public options: SelectOption[] = [];

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.fetchData(this.offerId);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['offerId']) {
      this.options = [];
      this.fetchData(changes['offerId'].currentValue);
    }

    if (changes['initialOption'] && this.initialOption) {
      this.options.push(this.initialOption);
    }
  }

  public fetchData(offerId: Nullable<string>): void {
    if (this.isEditMode) return;

    if (!offerId) {
      this.formControl.reset();
      this.options = [];
      return;
    }

    this.store
      .dispatch(new Offers.GetById({ id: offerId }))
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        const loadedOffers: SelectedVariantDto[] =
          data.offers?.details?.[this.offerId || '']?.variants.map(
            (variant: UpdateOfferVariantDto) => ({
              label: variant?.name?.['pl'] || '',
              value: variant.id,
              products: (variant.products || []).map(product => ({
                ...product,
                quantity: 0,
              })),
            })
          ) || [];

        const filteredOffers = loadedOffers.filter(
          x => !this.options.some(u => u.value === x.value)
        );

        this.options = [...this.options, ...filteredOffers];
        this.formControl.reset();
      });
  }

  public onVariantChange(selectedValue: Nullable<string>): void {
    const selectedOption = this.options.find(
      option => option.value === selectedValue
    ) as SelectedVariantDto;

    if (selectedOption) {
      this.variantsSelected.emit(selectedOption);
    }
    this.onChange(selectedValue);
  }

  public getOverlayOptions(): OverlayOptions {
    return {
      listener: (event: Event, options?: OverlayListenerOptions) => {
        if (options?.type === 'scroll') {
          return false;
        }
        return options?.valid;
      },
    };
  }

  public validate(): ValidationErrors | null {
    return this.formControl.valid
      ? null
      : {
          invalidForm: {
            valid: false,
            message: 'Invalid variant dropdown',
          },
        };
  }

  public registerOnChange(fn: (value: Nullable<string>) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: (value: Nullable<string>) => void): void {
    this.onTouched = fn;
  }

  public writeValue(data: Nullable<string>): void {
    if (this.formControl && this.formControl.value != data) {
      this.formControl.setValue(data, { emitEvent: false });
    }
  }
}
