<div class="layout">
  <div>
    <ng-content select="[left-menu]"></ng-content>
  </div>
  <div class="flex flex-column flex-grow-1 overflow-hidden">
    <ng-content select="[top-bar]"></ng-content>
    <div class="overflow-hidden">
      <ng-content></ng-content>
    </div>
  </div>
</div>
