import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { UiContext } from '../enums';

@Injectable()
export class UiContextService {
  public currentContext$ = new BehaviorSubject<UiContext>(
    (localStorage.getItem('context') as UiContext | null) ?? UiContext.Flyspot
  );

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.switchContext(this.currentContext$.getValue(), true);
  }

  public switchContext(context: UiContext, skipReload = false): void {
    const themeLink = this.document.getElementById('theme') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = `${context.toLowerCase()}.css`;
      localStorage.setItem('context', context);

      if (!skipReload) {
        if (this.router.url.startsWith('/location')) {
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        } else {
          window.location.reload();
        }
      }
    }
  }
}
