import {
  LanguageControlGroup,
  Nullable,
  OfferProductFormEnum,
  OfferProductPriceKindEnum,
  ProductKindEnum,
} from '@xspot-app/common';
import { OfferVariantProductSegmentPriceDto } from './offer-variant-product-segment-price.dto';

export interface OfferVariantProductDto {
  isMinimalAmount: Nullable<boolean>;
  id: string;
  productKind: ProductKindEnum;
  displayName: LanguageControlGroup;
  productForm: OfferProductFormEnum;
  minAmount?: Nullable<number>;
  maxAmount?: Nullable<number>;
  startAmount?: Nullable<number>;
  amount?: Nullable<number>;
  ordinal: number;
  priceKind: OfferProductPriceKindEnum;
  singlePrice: Nullable<number>;
  segmentPrices: OfferVariantProductSegmentPriceDto[];
}
