export class AuthErrorCodes {
  public static EmailConfirmationRequired = 'EmailConfirmationRequired';
  public static InvalidCredentials = 'InvalidCredentials';
  public static AccountLockedOut = 'AccountLockedOut';
  public static ValidationFailed = 'ValidationFailed';
  public static AccountIsBlocked = 'AccountIsBlocked';
}

export const AuthErrorMessages = {
  [AuthErrorCodes.EmailConfirmationRequired]: 'emailConfirmationRequired',
  [AuthErrorCodes.InvalidCredentials]: 'invalidCredentials',
  [AuthErrorCodes.AccountLockedOut]: 'accountLockedOut',
  [AuthErrorCodes.ValidationFailed]: 'validationFailed',
  [AuthErrorCodes.AccountIsBlocked]: 'accountIsBlocked',
};
