import { VolumePriceDiscountKindEnum } from '@xspot-app/common';
import {
  CustomerOfferModel,
  CustomerOfferVariantVolumePriceModel,
  CustomerOfferVariantProductVolumePriceProductModel,
} from '../domain/customer-offer.model';
import { CustomerOfferDto, CustomerOfferVariantProductDto } from '../dtos';

export class CustomerOfferModelMapper {
  public static map(dto: CustomerOfferDto): CustomerOfferModel {
    return {
      ...dto,
      variants: dto.variants.map(variant => ({
        ...variant,
        volumePrices: this.mapVolumePrices(
          variant.products.flatMap(p => (p.volumePrice ? [p] : []))
        ),
      })),
    };
  }

  private static mapVolumePrices(
    productsWithVolumePrice: CustomerOfferVariantProductDto[]
  ): CustomerOfferVariantVolumePriceModel[] {
    return [
      ...new Map(
        productsWithVolumePrice
          .flatMap(p => p.volumePrice!)
          .map(volumePrice => [
            volumePrice.id,
            {
              ...volumePrice,
              items: volumePrice.items
                .sort((a, b) => a.from - b.from)
                .map(item => {
                  return {
                    ...item,
                    discountValue:
                      {
                        [VolumePriceDiscountKindEnum.Percent]:
                          item.discountValue * 100,
                        [VolumePriceDiscountKindEnum.Price]: item.discountValue,
                      }[volumePrice.discountKind] || item.discountValue,
                  };
                }),
              products: productsWithVolumePrice
                .filter(p => p.volumePrice!.id === volumePrice.id)
                .flatMap(p => [
                  {
                    id: p.id,
                    displayName: p.displayName,
                  } as CustomerOfferVariantProductVolumePriceProductModel,
                ]),
              selectedProductCount: 0,
              isMaximumThresholdReached: false,
            } as CustomerOfferVariantVolumePriceModel,
          ])
      ).values(),
    ];
  }
}
