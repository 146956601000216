import {
  LanguageControlGroup,
  Nullable,
  OfferCategoryEnum,
  OfferMediaKindEnum,
  OfferProductPriceKindEnum,
  OfferProductFormEnum,
  SlotKindEnum,
  ProductKindEnum,
  OfferKindEnum,
} from '@xspot-app/common';

export interface UpdateOfferDto {
  id: string;
  coverMobile: UpdateFileDto;
  coverDesktop: UpdateFileDto;
  isHidden: boolean;
  name: LanguageControlGroup;
  teaser: LanguageControlGroup;
  description: LanguageControlGroup;
  kind: OfferKindEnum;
  limitedOffersNumber: Nullable<number>;
  maxOffersPerClient: Nullable<number>;
  legalContent: LanguageControlGroup;
  locationIds: string[];
  media: UpdateOfferMediaDto[];
  legalFiles: UpdateOfferLegalFileDto[];
  variants: UpdateOfferVariantDto[];
  upsellIds: string[];
  categories: OfferCategoryEnum[];
}

export interface UpdateOfferMediaDto {
  id: Nullable<string>;
  fileId: Nullable<string>;
  movieUri: Nullable<string>;
  kind: Nullable<OfferMediaKindEnum>;
  ordinal: number;
}

export interface UpdateOfferLegalFileDto {
  id: Nullable<string>;
  fileId: Nullable<string>;
  ordinal: number;
}

export interface UpdateOfferVariantDto {
  id: Nullable<string>;
  publicationStartDate: string;
  publicationEndDate: string;
  name: LanguageControlGroup;
  information: LanguageControlGroup;
  ordinal: number;
  entries: UpdateOfferVariantEntryDto[];
  products: UpdateOfferVariantProductDto[];
}

export interface UpdateOfferVariantEntryDto {
  header: LanguageControlGroup;
  description: LanguageControlGroup;
  ordinal: number;
}

export interface UpdateOfferVariantProductDto {
  id: Nullable<string>;
  productId: string;
  kind: ProductKindEnum;
  isMinimalAmount: Nullable<boolean>;
  ordinal: number;
  productForm: OfferProductFormEnum;
  minAmount: Nullable<number>;
  maxAmount: Nullable<number>;
  startAmount: Nullable<number>;
  amount: Nullable<number>;
  priceKind: OfferProductPriceKindEnum;
  singlePrice: Nullable<number>;
  segmentPrices: UpdateOfferVariantProductSegmentPriceDto[];
}

export interface UpdateOfferVariantProductSegmentPriceDto {
  slotKind: SlotKindEnum;
  price: number;
}

export interface UpdateFileDto {
  newFileId: Nullable<string>;
  fileUri: Nullable<string>;
}
