import { AbstractControl } from '@angular/forms';
import { DateTime } from 'luxon';
import { formDateFormat } from '../consts';

export function PastDateValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const inputValue: string = control.value;

  if (!inputValue) {
    return null;
  }

  const parsedDate = DateTime.fromFormat(inputValue, formDateFormat);
  const currentDate = DateTime.fromFormat(
    DateTime.now().toFormat(formDateFormat),
    formDateFormat
  );

  if (!parsedDate.isValid) {
    return null;
  }

  if (parsedDate >= currentDate) {
    return { dateInFuture: true };
  }

  return null;
}
