import { Injectable } from '@angular/core';
import { StateToken, State, Selector, Action, StateContext } from '@ngxs/store';
import { VoucherService } from '../../services';
import { VoucherStateModel } from './voucher.state-model';
import { VoucherDto } from '../../dtos';
import { Voucher } from './voucher.actions';
import { filter, finalize, Observable, tap } from 'rxjs';

const VOUCHER_STATE_TOKEN = new StateToken<VoucherStateModel>('voucher');

@State<VoucherStateModel>({
  name: VOUCHER_STATE_TOKEN,
  defaults: {
    vouchers: [],
    isLoading: false,
  },
})
@Injectable()
export class VoucherState {
  constructor(private voucherService: VoucherService) {}

  @Selector()
  public static getVouchers(state: VoucherStateModel): VoucherDto[] {
    return state.vouchers;
  }

  @Selector()
  public static isLoading(state: VoucherStateModel): boolean {
    return state.isLoading;
  }

  @Action(Voucher.ResetVouchers)
  public ResetVouchers(ctx: StateContext<VoucherStateModel>): void {
    ctx.patchState({
      vouchers: [],
      isLoading: false,
    });
  }

  @Action(Voucher.RemoveVoucher)
  public removeVoucher(
    ctx: StateContext<VoucherStateModel>,
    { id }: Voucher.RemoveVoucher
  ): void {
    const state = ctx.getState();
    const updatedVouchers = state.vouchers.filter(v => v.id !== id);

    ctx.patchState({
      vouchers: updatedVouchers,
    });
  }

  @Action(Voucher.GetVoucher)
  public getVoucher(
    ctx: StateContext<VoucherStateModel>,
    { payload }: Voucher.GetVoucher
  ): Observable<VoucherDto> {
    ctx.patchState({
      isLoading: true,
    });

    return this.voucherService.getVoucher(payload).pipe(
      filter((voucher): voucher is VoucherDto => !!voucher),
      tap(voucher => {
        const state = ctx.getState();
        const voucherExists = state.vouchers.some(v => v.id === voucher.id);

        if (!voucherExists) {
          const updatedVouchers = [...state.vouchers, voucher];
          ctx.patchState({
            vouchers: updatedVouchers,
          });
        }
      }),
      finalize(() => {
        ctx.patchState({
          isLoading: false,
        });
      })
    );
  }
}
