import { Injectable, NgZone } from '@angular/core';
import { MessageService } from 'primeng/api';
import { NotificationSeverityEnum } from './notification-severity.enum';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private messageService: MessageService,
    private translocoService: TranslocoService,
    private ngZone: NgZone
  ) {}

  public showSuccessNotification(token: string): void {
    this.showNotification(token, NotificationSeverityEnum.Success);
  }

  public showWarningNotification(token: string): void {
    this.showNotification(token, NotificationSeverityEnum.Warning);
  }

  public showErrorNotification(token: string): void {
    this.showNotification(token, NotificationSeverityEnum.Error);
  }

  private showNotification(
    token: string,
    severity: NotificationSeverityEnum
  ): void {
    this.ngZone.run(() => {
      this.messageService.add({
        severity: severity,
        summary: this.translocoService.translate(token),
      });
    });
  }
}
